import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.madflowers.es:8443/madflowers-web/api/', // Coloca aquí la URL base de tu backend de Spring
  // Puedes agregar otras configuraciones aquí, como encabezados personalizados
  // https://api.madflowers.es:8443 - AWS
  // localhost:9090 - local
});

export default instance;
