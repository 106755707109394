// imageMap.js
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const iconMap = {
  football: <SportsSoccerIcon />,
  basketball: <SportsBasketballIcon />,
  esports: <SportsEsportsIcon />,
};

export default iconMap;
