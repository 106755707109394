import React from 'react';
import useStyles from '../styles/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.footerContent}>
          <p className={classes.footerText}>
            © 2024 Mad Flowers. Todos los derechos reservados.
          </p>
          <div className={classes.socialIcons}>
            <a href="https://www.instagram.com/madflowers.es" className={classes.socialIcon}>
              <InstagramIcon />
            </a>
            <a href="https://twitter.com/madflowersfc" className={classes.socialIcon}>
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
