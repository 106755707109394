import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig';
import { Box } from '@mui/material';
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import PlayerDisplayHeader from "../../components/players/PlayerDisplayHeader";
import PlayerDisplayTabs from "../../components/players/PlayerDisplayTabs";

const PlayerDisplay = () => {
    const { playerId } = useParams();
    const [player, setPlayer] = useState(null); // Inicializa como null o un objeto vacío, no una matriz
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/players/' + playerId);
                setPlayer(response.data);
            } catch (error) {
                console.error('Error al obtener los datos del jugador:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [playerId]);

    return (
        <Box>
            {isLoading ? (
                <Loading text="Cargando datos..." />
            ) : (
                <Box>
                    <PlayerDisplayHeader player={player} />
                    <PlayerDisplayTabs player={player} />
                </Box>
            )
        }
            <Footer />
        </Box>
    );
};

export default PlayerDisplay;
