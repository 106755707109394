import useStyles from "../../styles/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axiosConfig"; // Importa el objeto Axios configurado
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import TeamDisplayDetails from "../../components/teams/TeamDisplayDetails";
import TeamDisplayHeader from "../../components/teams/TeamDisplayHeader";
import TeamDisplaySquad from "../../components/teams/TeamDisplaySquad";
import TeamDisplayStats from "../../components/teams/TeamDisplayStats";
import TeamDisplayStandings from "../../components/teams/TeamDisplayStandings";
import TeamDisplayCalendar from "../../components/teams/TeamDisplayCalendar";
import TeamDisplayHistory from "../../components/teams/TeamDisplayHistory";
import Footer from "../../components/Footer";

const TeamDisplay = () => {
  const { teamId } = useParams();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [players, setPlayers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchesFinished, setMatchesFinished] = useState([]);
  const [staff, setStaff] = useState([]);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const [activeTab, setActiveTab] = useState("details"); // Estado para el seguimiento de la pestaña activa

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // Realizar una solicitud GET al backend de Spring
    axios
      .get("/teams/" + teamId)
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        setData(response.data);
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error("Error al obtener los datos:", error);
      });
    axios
      .get("/teams/" + teamId + "/players")
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        setPlayers(response.data);
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error("Error al obtener los datos:", error);
      });
    axios
      .get("/teams/" + teamId + "/matches/" + "2023-2024")
      .then((response) => {
        setMatches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching matches:", error);
      });
    axios
      .get("/teams/" + teamId + "/history")
      .then((response) => {
        setMatchesFinished(response.data);
      })
      .catch((error) => {
        console.error("Error fetching finished matches:", error);
      });
    axios
      .get("/teams/" + teamId + "/staff")
      .then((response) => {
        setStaff(response.data);
      })
      .catch((error) => {
        console.error("Error fetching team staff:", error);
      });
  }, []);

  return (
    <Box>
      <TeamDisplayHeader team={data} />
      <div
        className={
          isMobile ? classes.teamDisplayTabsMobile : classes.teamDisplayTabs
        }
      >
        <button
          className={
            activeTab === "details" && isMobile
              ? classes.teamDisplayActiveTabMobile
              : activeTab === "details" && !isMobile
              ? classes.teamDisplayActiveTab
              : !isMobile
              ? classes.teamDisplayTab
              : classes.teamDisplayTabMobile
          }
          onClick={() => handleTabChange("details")}
        >
          Detalles
        </button>
        <button
          className={
            activeTab === "squad" && isMobile
              ? classes.teamDisplayActiveTabMobile
              : activeTab === "squad" && !isMobile
              ? classes.teamDisplayActiveTab
              : !isMobile
              ? classes.teamDisplayTab
              : classes.teamDisplayTabMobile
          }
          onClick={() => handleTabChange("squad")}
        >
          Plantilla
        </button>
        <button
          className={
            activeTab === "stats" && isMobile
              ? classes.teamDisplayActiveTabMobile
              : activeTab === "stats" && !isMobile
              ? classes.teamDisplayActiveTab
              : !isMobile
              ? classes.teamDisplayTab
              : classes.teamDisplayTabMobile
          }
          onClick={() => handleTabChange("stats")}
        >
          Estadísticas
        </button>

        <button
          className={
            activeTab === "standings" && isMobile
              ? classes.teamDisplayActiveTabMobile
              : activeTab === "standings" && !isMobile
              ? classes.teamDisplayActiveTab
              : !isMobile
              ? classes.teamDisplayTab
              : classes.teamDisplayTabMobile
          }
          onClick={() => handleTabChange("standings")}
        >
          Clasificación
        </button>

        <button
          className={
            activeTab === "history" && isMobile
              ? classes.teamDisplayActiveTabMobile
              : activeTab === "history" && !isMobile
              ? classes.teamDisplayActiveTab
              : !isMobile
              ? classes.teamDisplayTab
              : classes.teamDisplayTabMobile
          }
          onClick={() => handleTabChange("history")}
        >
          Historial
        </button>
        <button
          className={
            activeTab === "calendar" && isMobile
              ? classes.teamDisplayActiveTabMobile
              : activeTab === "calendar" && !isMobile
              ? classes.teamDisplayActiveTab
              : !isMobile
              ? classes.teamDisplayTab
              : classes.teamDisplayTabMobile
          }
          onClick={() => handleTabChange("calendar")}
        >
          Calendario
        </button>
      </div>
      <div className={classes.teamDisplayMainSection}>
        {activeTab === "details" && (
          <TeamDisplayDetails
            team={data}
            matches={matchesFinished}
            staff={staff}
          />
        )}
        {activeTab === "squad" && <TeamDisplaySquad players={players} />}
        {activeTab === "stats" && <TeamDisplayStats team={data} />}
        {activeTab === "standings" && <TeamDisplayStandings team={data} />}
        {activeTab === "history" && (
          <TeamDisplayHistory matches={matchesFinished} />
        )}
        {activeTab === "calendar" && (
          <TeamDisplayCalendar matches={matches} sport={data.sport} />
        )}
      </div>
      <Footer />
    </Box>
  );
};

export default TeamDisplay;
