import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { FaInstagram, FaTwitter, FaFacebook, FaTiktok, FaThreads, FaStar, FaFlag, FaCalendarDay, FaHands } from 'react-icons/fa6';
import { IoFootsteps, IoBarbellSharp  } from "react-icons/io5";
import { MdDriveFileRenameOutline } from "react-icons/md";

const PlayerDisplayPersonal = ({ player, socials }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const trimTimeFromDate = (dateTime) => {
        // Separar la cadena de fecha en sus partes de fecha y hora
        const [datePart, timePart] = dateTime.split(" ");
        
        // Devolver solo la parte de la fecha
        return datePart;
    };

    const calculateAge = (birthDate) => {
        const dateParts = birthDate.split("/");
        const birthDateDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        const currentDate = new Date();
        
        let age = currentDate.getFullYear() - birthDateDate.getFullYear();
        
        const mesActual = currentDate.getMonth();
        const mesNacimiento = birthDateDate.getMonth();
        
        if (mesActual < mesNacimiento || (mesActual === mesNacimiento && currentDate.getDate() < birthDateDate.getDate())) {
          age--;
        }
        
        return age;
      };

    return (
        <Box>
            <div className={isMobile ? classes.playerDisplayPersonalContainerMobile : classes.playerDisplayPersonalContainer}>
                {player.person.birthDate &&
                <div className={isMobile ? classes.playerDisplayPersonalElementMobile : classes.playerDisplayPersonalElement}>
                    <div className={isMobile ? classes.playerDisplayPersonalElementTitleMobile : classes.playerDisplayPersonalElementTitle}>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleIconMobile : classes.playerDisplayPersonalElementTitleIcon}>
                            <FaCalendarDay size={16}/>
                        </div>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleNameMobile : classes.playerDisplayPersonalElementTitleName}>
                            Fecha de nacimiento
                        </div>
                    </div>
                    <div className={isMobile ? classes.playerDisplayPersonalElementValueMobile : classes.playerDisplayPersonalElementValue}>
                        {trimTimeFromDate(player.person.birthDate)} ({calculateAge(trimTimeFromDate(player.person.birthDate))})
                    </div>
                </div>
                }
                {player.person.nationality &&
                <div className={isMobile ? classes.playerDisplayPersonalElementMobile : classes.playerDisplayPersonalElement}>
                    <div className={isMobile ? classes.playerDisplayPersonalElementTitleMobile : classes.playerDisplayPersonalElementTitle}>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleIconMobile : classes.playerDisplayPersonalElementTitleIcon}>
                            <FaFlag size={16}/>
                        </div>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleNameMobile : classes.playerDisplayPersonalElementTitleName}>
                            Nacionalidad
                        </div>
                    </div>
                    <div className={isMobile ? classes.playerDisplayPersonalElementValueMobile : classes.playerDisplayPersonalElementValue}>
                        <span className={`fi fi-${player.person.nationality}`}></span>
                    </div>
                </div>
                }
                {player.mainSide && player.weight &&
                <div className={isMobile ? classes.playerDisplayPersonalElementMobile : classes.playerDisplayPersonalElement}>
                    <div className={isMobile ? classes.playerDisplayPersonalElementTitleMobile : classes.playerDisplayPersonalElementTitle}>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleIconMobile : classes.playerDisplayPersonalElementTitleIcon}>
                            <IoBarbellSharp size={18}/>
                        </div>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleNameMobile : classes.playerDisplayPersonalElementTitleName}>
                            Medidas
                        </div>
                    </div>
                    <div className={isMobile ? classes.playerDisplayPersonalElementValueMobile : classes.playerDisplayPersonalElementValue}>
                        {player.height} cm / {player.weight} kg
                    </div>
                </div>
                }
                {player.nickname &&
                <div className={isMobile ? classes.playerDisplayPersonalElementMobile : classes.playerDisplayPersonalElement}>
                    <div className={isMobile ? classes.playerDisplayPersonalElementTitleMobile : classes.playerDisplayPersonalElementTitle}>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleIconMobile : classes.playerDisplayPersonalElementTitleIcon}>
                            <MdDriveFileRenameOutline size={18}/>
                        </div>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleNameMobile : classes.playerDisplayPersonalElementTitleName}>
                            Apodo
                        </div>
                    </div>
                    <div className={isMobile ? classes.playerDisplayPersonalElementValueMobile : classes.playerDisplayPersonalElementValue}>
                        {player.nickname}
                    </div>
                </div>
                }
                {player.mainSide && player.weakSide && player.team.sport === 'football' &&
                    <div className={isMobile ? classes.playerDisplayPersonalElementMobile : classes.playerDisplayPersonalElement}>
                    <div className={isMobile ? classes.playerDisplayPersonalElementTitleMobile : classes.playerDisplayPersonalElementTitle}>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleIconMobile : classes.playerDisplayPersonalElementTitleIcon}>
                            <IoFootsteps size={18}/>
                        </div>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleNameMobile : classes.playerDisplayPersonalElementTitleName}>
                            Pies
                        </div>
                    </div>
                    <div className={isMobile ? classes.playerDisplayPersonalElementValueMobile : classes.playerDisplayPersonalElementValue}>
                    {player.mainSide === 'R' ? 'DIESTRO' : player.mainSide === 'L' ? 'ZURDO' : player.mainSide} / {player.weakSide} <FaStar />
                    </div>
                </div>
                }

                {player.mainSide && player.weakSide && player.team.sport === 'basketball' && (
                    <div className={isMobile ? classes.playerDisplayPersonalElementMobile : classes.playerDisplayPersonalElement}>
                        <div className={isMobile ? classes.playerDisplayPersonalElementTitleMobile : classes.playerDisplayPersonalElementTitle}>
                            <div className={isMobile ? classes.playerDisplayPersonalElementTitleIconMobile : classes.playerDisplayPersonalElementTitleIcon}>
                                <FaHands size={18}/>
                            </div>
                            <div className={isMobile ? classes.playerDisplayPersonalElementTitleNameMobile : classes.playerDisplayPersonalElementTitleName}>
                                Manos
                            </div>
                        </div>
                        <div className={isMobile ? classes.playerDisplayPersonalElementValueMobile : classes.playerDisplayPersonalElementValue}>
                            {player.mainSide === 'R' ? 'DIESTRO' : player.mainSide === 'L' ? 'ZURDO' : player.mainSide} / {player.weakSide} <FaStar />
                        </div>
                    </div>
                )}
            </div>
            {
                (socials.instagram || socials.twitter || socials.tiktok || socials.facebook || socials.threads) && 
                <div className={isMobile ? classes.playerDisplaySocialsMobile : classes.playerDisplaySocials}>
                Sigue a <span>{player.person.name} {player.person.surname}</span> en redes:
                <div className={isMobile ? classes.playerDisplaySocialsContainerMobile : classes.playerDisplaySocialsContainer}>
                    {socials.instagram &&
                        <a href={`https://www.instagram.com/${socials.instagram}`} target="_blank" rel="noreferrer" className={isMobile ? classes.playerDisplaySocialsElementMobile : classes.playerDisplaySocialsElement}>
                            <div className={isMobile ? classes.playerDisplaySocialsElementTitleMobile : classes.playerDisplaySocialsElementTitle}>
                                <FaInstagram />
                            </div>
                            <div className={isMobile ? classes.playerDisplaySocialsElementValueMobile : classes.playerDisplaySocialsElementValue}>
                                {socials.instagram}
                            </div>
                        </a>
                    }
                    {socials.twitter &&
                        <a href={`https://www.twitter.com/${socials.twitter}`} target="_blank" rel="noreferrer" className={isMobile ? classes.playerDisplaySocialsElementMobile : classes.playerDisplaySocialsElement}>
                            <div className={isMobile ? classes.playerDisplaySocialsElementTitleMobile : classes.playerDisplaySocialsElementTitle}>
                                <FaTwitter />
                            </div>
                            <div className={isMobile ? classes.playerDisplaySocialsElementValueMobile : classes.playerDisplaySocialsElementValue}>
                                {socials.twitter}
                            </div>
                        </a>
                    }
                    {socials.tiktok &&
                        <a href={`https://www.tiktok.com/${socials.tiktok}`} target="_blank" rel="noreferrer" className={isMobile ? classes.playerDisplaySocialsElementMobile : classes.playerDisplaySocialsElement}>
                            <div className={isMobile ? classes.playerDisplaySocialsElementTitleMobile : classes.playerDisplaySocialsElementTitle}>
                                <FaTiktok />
                            </div>
                            <div className={isMobile ? classes.playerDisplaySocialsElementValueMobile : classes.playerDisplaySocialsElementValue}>
                                {socials.tiktok}
                            </div>
                        </a>
                    }
                    {socials.facebook &&
                        <a href={`https://www.facebook.com/${socials.facebook}`} target="_blank" rel="noreferrer" className={isMobile ? classes.playerDisplaySocialsElementMobile : classes.playerDisplaySocialsElement}>
                            <div className={isMobile ? classes.playerDisplaySocialsElementTitleMobile : classes.playerDisplaySocialsElementTitle}>
                                <FaFacebook />
                            </div>
                            <div className={isMobile ? classes.playerDisplaySocialsElementValueMobile : classes.playerDisplaySocialsElementValue}>
                                {socials.instagram}
                            </div>
                        </a>
                    }
                    {socials.threads &&
                        <a href={`https://www.threads.net/@${socials.threads}`} target="_blank" rel="noreferrer" className={classes.playerDisplayisMobile ? classes.playerDisplaySocialsElementMobile : classes.playerDisplaySocialsElement}>
                            <div className={isMobile ? classes.playerDisplaySocialsElementTitleMobile : classes.playerDisplaySocialsElementTitle}>
                                <FaThreads />
                            </div>
                            <div className={isMobile ? classes.playerDisplaySocialsElementValueMobile : classes.playerDisplaySocialsElementValue}>
                                {socials.threads}
                            </div>
                        </a>
                    }
                </div>
            </div>
            }
            
        </Box>
    );
};

export default PlayerDisplayPersonal;