import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts'; // Importar todos los símbolos de echarts
import { tokens } from '../../theme.js';

function EChartsAssistsComponent({ data }) {
  const chartRef = useRef(null);

  const colors = tokens("dark");

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    
    let matchweekData = []; 
    let goalsData = [];
    if (data) {
        matchweekData = data.map(item => item.matchweek);
        goalsData = data.map(item => item.stats.assists);
    }
    
    const option = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: matchweekData,
        name: 'Jornada',
        axisLabel: {
          textStyle: {
            color: colors.rose[500],
            fontSize: '1rem',
          }
        },
        nameTextStyle: {
          color: colors.menubar[100],
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }
      },
      yAxis: {
        type: 'value',
        name: 'Asistencias',
        axisLabel: {
          textStyle: {
            color: colors.rose[500],
            fontSize: '1rem',
          }
        },
        nameTextStyle: {
          color: colors.menubar[100],
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }
      },
      series: [
        {
          data: goalsData,
          type: 'line',
          itemStyle: {
            color: colors.rose[500]
          },
          areaStyle: {
            color: colors.rose[600]
          }
        }
      ]
    };
    
    chart.setOption(option);
  }, [data]);

  return (
        <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
    );
}

export default EChartsAssistsComponent;
