import React from 'react';
import useStyles from '../../styles/styles';
import EventIcon from '@mui/icons-material/Event';
import Match from './Match';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';


const NextMatches = () => {

    const classes = useStyles();
    return (
        <section className={classes.nextMatches}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <EventIcon /> Próximos partidos
                </h2>
                <div className={classes.nextMatchContainer}>
                    <Match icon={<SportsBasketballIcon />} idTeam={2} />
                    <Match icon={<SportsSoccerIcon />} idTeam={1} />
                </div>
            </div>
        </section>
    );
};

export default NextMatches;