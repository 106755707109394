import React from "react";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";

function History() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  return (
    <section id="history" className={classes.aboutUsSection}>
      <h2 className={classes.aboutUsTitle}>Nuestra historia</h2>

      <h3 className={classes.aboutUsSubtitle}>Nacimiento</h3>

      <p>
        En 2022, un grupo de amigos de Vallecas, Madrid, unidos por la pasión
        por el deporte, dio vida a Mad Flowers. Desde sus inicios, el club ha
        experimentado un crecimiento sostenido, tanto en número de socios como
        en la calidad de sus equipos.
      </p>

      <h3 className={classes.aboutUsSubtitle}>Nuestra esencia</h3>

      <p>
        Somos un club que busca fomentar la deportividad, el compañerismo, el
        respeto y la igualdad. Más que un equipo, somos una familia que disfruta
        del deporte y crea grandes momentos juntos.
      </p>

      <h3 className={classes.aboutUsSubtitle}>UN VIAJE DE CRECIMIENTO</h3>

      <p>
        En nuestro primer año, nos consolidamos como una referencia en el
        deporte local, participando en las ligas locales y dejando huella. El
        éxito nos impulsó a crear un nuevo equipo de baloncesto y fortalecer el
        equipo de fútbol.
      </p>

      <h3 className={classes.aboutUsSubtitle}>Presente y futuro</h3>

      <p>
        Si eres un apasionado del fútbol o del baloncesto y buscas un club con
        un ambiente familiar y una gran tradición deportiva, ¡no dudes en
        contactarnos! ¡Forma parte de Mad Flowers!
      </p>
    </section>
  );
}

export default History;
