import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from '../../styles/styles';

const Topbar = ({ onDrawerToggle }) => {

    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="space-between" p={2} className={classes.topbar}>
            <Box display="flex">
                <IconButton type="button" onClick={onDrawerToggle}>
                    <MenuIcon />
                </IconButton>
            </Box>
            <Box display="flex">
                {/* <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton> */}
                {/* <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton> */}
            </Box>
        </Box>
    );
};

export default Topbar;