import React from "react";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import awardImageMap from "../../maps/awardImageMap";
import awardNameMap from "../../maps/awardNameMap";
import { IoFootball, IoBasketballSharp } from "react-icons/io5";
import { MdOutlineSports } from "react-icons/md";

const VotingEntry = (voting) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  var sportIcon;

  voting = voting.voting;

  if (voting.match.localTeam.sport === "football") {
    sportIcon = <IoFootball size={24}/>;
  } else if (voting.match.localTeam.sport === "basketball") {
    sportIcon = <IoBasketballSharp size={24}/>;
  } else {
    sportIcon = <MdOutlineSports size={24}/>;
  }

  // Función para obtener el nombre del mes a partir de su número
  const getMonthName = (monthNumber) => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return monthNames[monthNumber - 1];
  };

  // Función para obtener el mes y año a partir de la fecha en formato "dd/mm/yyyy hh:mm:ss"
  const getMonthAndYear = (dateString) => {
    const parts = dateString.split(" "); // Divide la cadena en partes utilizando el espacio como separador
    const datePart = parts[0]; // Obtiene la parte de la fecha
    const [day, month, year] = datePart.split("/"); // Divide la fecha en día, mes y año utilizando "/" como separador
    return `${getMonthName(parseInt(month))} ${year}`;
  };

  // Función para formatear la fecha quitando los segundos
  const formatDateTime = (dateTimeString) => {
    const parts = dateTimeString.split(" ");
    const datePart = parts[0];
    const timePart = parts[1];
    const [day, month, year] = datePart.split("/");
    const formattedDate = `${day}/${month}/${year}`;
    return `${formattedDate} ${timePart.slice(0, -3)}`; // Elimina los últimos 3 caracteres (segundos)
  };

  return (
    <a
      className={isMobile ? classes.votingsEntryMobile : classes.votingsEntry}
      href={"/vote/" + voting.idVoting}
    >
      <div
        className={
          isMobile
            ? classes.votingsEntryMatchPreviewMobile
            : classes.votingsEntryMatchPreview
        }
      >
        <img
          src={awardImageMap[voting.details] || awardImageMap["default"]}
          alt=""
        />
      </div>
      {voting.details === "POTG" ? (
        <div className={classes.votingsEntryDetails}>
          <h3 className={classes.votingsEntryDetailsType}>
            {awardNameMap[voting.details] || awardNameMap["default"]}
          </h3>
          <div className={classes.votingsEntryDetailsLittle}>
            Jornada {voting.match.matchweek}
          </div>
          <div className={classes.votingsEntryDetailsLittle}>
            {formatDateTime(voting.match.date)}
          </div>
          <div>{sportIcon}</div>
        </div>
      ) : (
        <div className={classes.votingsEntryDetails}>
          <h3 className={classes.votingsEntryDetailsType}>
            {awardNameMap[voting.details] || awardNameMap["default"]}
          </h3>
          <div className={classes.votingsEntryDetailsLittle}>
            {voting.details === "POTM" && getMonthAndYear(voting.match.date)}
            {voting.details === "POTY" && ("Temporada " + voting.match.season)}
          </div>
          <div>{sportIcon}</div>
        </div>
      )}
    </a>
  );
};

export default VotingEntry;
