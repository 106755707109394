import React from 'react';
import { Box } from '@mui/material';
import useStyles from '../styles/styles';
import {useMediaQuery} from '@mui/material';
import backgroundImageMap from '../maps/backgroundImageMap.js';

const PageHeader = ({ headerTitle }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const getBackgroundURL = (value) => {
        return backgroundImageMap[value] || backgroundImageMap["playerBackground"];
      };

    const headerBackground = getBackgroundURL("section");

    const backgroundHeaderStyle = {
        /* Estilos para el playerDisplayHeader si los tienes */
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: 'cover',
    };

    return (
        <Box className={isMobile ? classes.teamsHeaderMobile : classes.teamsHeader} style={backgroundHeaderStyle}>
            <div>
                {headerTitle}
            </div>
        </Box>
    );
};

export default PageHeader;