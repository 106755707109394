// imageMap.js
const getOtherImageUrl = ( otherImageName ) => {
    return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/other/' + otherImageName;
  }
  
  const imageMap = {
    cookies: getOtherImageUrl('cookies.png'),
    default: getOtherImageUrl('cookies.png'),
  };
  
  export default imageMap;