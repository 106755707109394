import React from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import CalendarComponent from '../calendar/CalendarComponent';

const TeamDisplayCalendar = ({ matches, sport }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    return (
        <Box>
            <CalendarComponent matches={matches} sport={sport}/>
        </Box>
    );
};

export default TeamDisplayCalendar;