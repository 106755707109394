import React from 'react';
import { Box } from '@mui/material';
import useStyles from '../../../styles/styles';
import {useMediaQuery} from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TableChartIcon from '@mui/icons-material/TableChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EChartsGoalsComponent from '../../charts/EChartsGoalsComponent';
import EChartsAssistsComponent from '../../charts/EChartsAssistsComponent';

const TeamDisplayStatsFootball = ({ stats, evolutionStats, hideGraphs}) => {

    const isMobile = useMediaQuery('(max-width: 800px)');
    const classes = useStyles();

    return (
        <Box className={isMobile ? classes.teamDisplayStatsFootballMobile : classes.teamDisplayStatsFootball}>
            <div className={classes.teamDisplayStatsFootballSectionTitle}> <AssessmentIcon /> Estadísticas principales</div>
            <div className={isMobile ? classes.teamDisplayStatsFootballMainStatsGridMobile : classes.teamDisplayStatsFootballMainStatsGrid}>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Partidos jugados</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.numberOfMatches}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Victorias</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.wins}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Derrotas</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.losses}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Goles</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.goals}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Goles en contra</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.goalsConceded}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Porterías a cero</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.cleanSheets}</div>
                </div>
            </div>
            <div className={classes.teamDisplayStatsFootballSectionTitle}><TableChartIcon /> Estadísticas avanzadas</div>
            <div className={isMobile ? classes.teamDisplayStatsFootballMoreStatsGridMobile : classes.teamDisplayStatsFootballMoreStatsGrid}>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>Ataque</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Goles</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.goals}</span>
                            </li>
                            <li>
                                <span>Goles por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.goalsPerMatch}</span>
                            </li>
                            <li>
                                <span>Asistencias</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.assists}</span>
                            </li>
                            <li>
                                <span>Asistencias por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.assistsPerMatch}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>Defensa</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Goles en contra</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.goalsConceded}</span>
                            </li>
                            <li>
                                <span>Porterías a cero</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.cleanSheets}</span>
                            </li>
                            <li>
                                <span>Porcentaje de portería a cero</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.cleanSheetPercentage * 100 + "%"}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>Disciplina</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Tarjetas amarillas</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.yellowCards}</span>
                            </li>
                            <li>
                                <span>Tarjetas amarillas por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.yellowCardsPerMatch}</span>
                            </li>
                            <li>
                                <span>Tarjetas rojas</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.redCards}</span>
                            </li>
                            <li>
                                <span>Tarjetas rojas por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.redCardsPerMatch}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {hideGraphs && 
            <div>
                <div className={classes.teamDisplayStatsFootballSectionTitle}><ShowChartIcon /> Gráficos</div>
                <div className={classes.teamDisplayStatsFootballGraphs}>
                    <div className={classes.teamDisplayStatsFootballGraphTitle}>GOLES</div>
                    {evolutionStats && <div style={{ width: '100%', height: '400px' }}>
                        <EChartsGoalsComponent data={evolutionStats}/>
                    </div>}
                    <div className={classes.teamDisplayStatsFootballGraphTitle}>ASISTENCIAS</div>
                    {evolutionStats && <div style={{ width: '100%', height: '400px' }}>
                        <EChartsAssistsComponent data={evolutionStats}/>
                    </div>}
                </div>
            </div>
            }
        </Box>
    );
};

export default TeamDisplayStatsFootball;