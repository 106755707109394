import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import TeamDisplayStatsBasketball from './stats/TeamDisplayStatsBasketball';
import TeamDisplayStatsFootball from './stats/TeamDisplayStatsFootball';

const TeamDisplayStats = ({ team }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [stats, setStats] = useState('');
    const [evolutionStats, setEvolutionStats] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        // Obtener la lista de opciones mediante Axios
        axios.get('/teams/' + team.idTeam + '/stats/seasons')
          .then(response => {
            setOptions(response.data);
          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
      }, []);

    useEffect(() => {
          axios.get('/teams/' + team.idTeam + '/stats/' + selectedOption)
            .then(response => {
              setStats(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
    }, [selectedOption]);

    useEffect(() => {
      if (selectedOption){
        axios.get('/teams/' + team.idTeam + '/stats/' + selectedOption + '/evolution')
        .then(response => {
          setEvolutionStats(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      }
    }, [selectedOption]);

    return (
        <Box className={isMobile ? classes.teamDisplayStatsMobile : classes.teamDisplayStats}>
          <Box className={classes.teamDisplayStatsSelectContainer}>
            <select className={classes.teamDisplayStatsSelect} value={selectedOption} onChange={handleOptionChange}>
                  <option className={classes.teamDisplayStatsSelectOption} value="total">Total</option>
                  {options.map(option => (
                      <option className={classes.teamDisplayStatsSelectOption} key={option} value={option}>
                          {option}
                      </option>
                  ))}
              </select>
          </Box>
          <Box className={isMobile ? classes.teamDisplayStatsContainerMobile : classes.teamDisplayStatsContainer}>
            {team.sport === 'football' && <TeamDisplayStatsFootball stats={stats} evolutionStats={evolutionStats} hideGraphs={!(selectedOption === 'total' || selectedOption === '')}/>}
            {team.sport === 'basketball' && <TeamDisplayStatsBasketball stats={stats} evolutionStats={evolutionStats} hideGraphs={!(selectedOption === 'total' || selectedOption === '')}/>}
          </Box>
        </Box>
    );
};

export default TeamDisplayStats;