import React from 'react';
import { Link } from 'react-router-dom';
import {useMediaQuery, Drawer, List, ListItemButton, ListItemIcon, ListItemText, IconButton} from '@mui/material';
import useStyles from '../../styles/styles';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventIcon from '@mui/icons-material/Event';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BackupIcon from '@mui/icons-material/Backup';

const SidebarItem = ({link, icon, text, onClick}) => {
  return (
    <ListItemButton component={Link} to={link} onClick={onClick}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
}

const Sidebar = ({ open, onClose }) => {

  const isMobile = useMediaQuery('(max-width: 600px)'); // Modifica el valor para adaptarse a tus necesidades
  const classes = useStyles();


    return (
      <Drawer open={open} onClose={onClose} sx={{
        '& .MuiDrawer-paper': {
          width: isMobile ? '100vw' : 400, // Si es móvil, ocupa toda la pantalla (100vw), si no, tiene un ancho de 300px
          backgroundImage: 'none', // Quita la imagen de fondo
        },
      }}>
        {isMobile && (
          <div  className={classes.sidebarMobileButton} >
            <CloseIcon fontSize="large" onClick={onClose} />
          </div>
      )}
        <List className={classes.drawerContainer}>
          <SidebarItem link="" icon={<HomeIcon />} text="Inicio" onClick={onClose}/>
          <SidebarItem link="about" icon={<PeopleAltIcon />} text="Sobre nosotros" onClick={onClose}/>
          <SidebarItem link="team" icon={<GroupsIcon />} text="Equipos" onClick={onClose}/>
          {/* <SidebarItem link="stats" icon={<BarChartIcon />} text="Estadísticas" /> */}
          {/* <SidebarItem link="calendar" icon={<EventIcon />} text="Calendario" /> */}
          <SidebarItem link="vote" icon={<HowToVoteIcon />} text="Votaciones" onClick={onClose}/>
          {/* <SidebarItem link="news" icon={<NewspaperIcon />} text="Noticias" /> */}
          <SidebarItem link="contact" icon={<AlternateEmailIcon />} text="Contacto" onClick={onClose}/>
          {/* <SidebarItem link="" icon={<BackupIcon />} text="Black Rose Interno" /> */}
        </List>
      </Drawer>
    );
  };
  
  export default Sidebar;