import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import playerImageMap from '../../maps/playerImageMap';
import { Table, TableBody, TableCell, TableHead, TableRow, Avatar, Hidden } from '@mui/material';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const TeamDisplaySquad = ({ players }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const styleHeader = { 
        fontWeight: 'bold', 
        textTransform: 'uppercase', 
        textAlign: 'center',
        fontSize: '0.7rem'
    };

    const styleCellNormal = { 
        textAlign: 'center',
        fontSize: '0.7rem',
        margin: '0 auto',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const styleCellBold = { 
        textAlign: 'center',
        fontSize: '0.7rem',
        fontWeight: 'bold',
    };

    const styleCenterImage = {
        margin: '0 auto',
    }

    const trimTimeFromDate = (dateTime) => {
        // Separar la cadena de fecha en sus partes de fecha y hora
        const [datePart, timePart] = dateTime.split(" ");
        
        // Devolver solo la parte de la fecha
        return datePart;
    };

    function handleClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <Box className={isMobile ? classes.teamDisplaySquadMobile : classes.teamDisplaySquad}>
            <Table className={isMobile ? classes.teamDisplaySquadTableMobile : classes.teamDisplaySquadTable}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={styleHeader}>Nombre</TableCell>
                        <TableCell style={styleHeader}>Dorsal</TableCell>
                        <TableCell style={styleHeader}>Posición</TableCell>
                        <Hidden smDown>
                            <TableCell style={styleHeader}>Nacionalidad</TableCell>
                        </Hidden>
                        <Hidden mdDown>
                            <TableCell style={styleHeader}>Apodo</TableCell>
                            <TableCell style={styleHeader}>Altura</TableCell>
                            {/* <TableCell>Peso</TableCell> */}
                            <TableCell style={styleHeader}>Fecha de nacimiento</TableCell>
                        </Hidden>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.map((player, index) => (
                        <TableRow key={index}>
                            <TableCell><Avatar alt="" src={playerImageMap[player.imageLocator] || playerImageMap["default"]} style={styleCenterImage}/></TableCell>
                            <TableCell style={styleCellNormal}>{player.person.name} {player.person.surname}</TableCell>
                            <TableCell style={styleCellNormal}>{player.number}</TableCell>
                            <TableCell style={styleCellNormal}>{player.position}</TableCell>
                            <Hidden smDown>
                                <TableCell style={styleCellNormal}>
                                    <span className={`fi fi-${player.person.nationality}`}></span>
                                </TableCell>
                            </Hidden>
                            <Hidden mdDown>
                                <TableCell style={styleCellNormal}>{player.nickname}</TableCell>
                                <TableCell style={styleCellNormal}>{player.height} cm</TableCell>
                                {/* <TableCell>{player.weight} kg</TableCell> */}
                                <TableCell style={styleCellNormal}>{trimTimeFromDate(player.person.birthDate)}</TableCell>
                            </Hidden>
                            <TableCell><Link to={"/player/" + player.idPlayer} className={classes.teamDisplaySquadTableButton} onClick={handleClick}><VisibilityIcon/></Link></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );

    
};

export default TeamDisplaySquad;