const getStaffImageUrl = ( staffImageName ) => {
    return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/staff/' + staffImageName;
  }
  
  
  const playerImageMap = {
    miguelcarmonastaff: getStaffImageUrl('miguelcarmonastaff.png'),
    patriciacortesstaff: getStaffImageUrl('patriciacortesstaff.png'),
    jorgepradanastaff: getStaffImageUrl('jorgepradanastaff.png'),
    jorgenavarrostaff: getStaffImageUrl('jorgenavarrostaff.png'),
    diegomatiasstaff: getStaffImageUrl('diegomatiasstaff.png'),
    martaastraystaff: getStaffImageUrl('martaastraystaff.png'),
    nataliaortegastaff: getStaffImageUrl('nataliaortegastaff.png'),
    alvarodeporrasstaff: getStaffImageUrl('alvarodeporrasstaff.png'),
    laracastrostaff: getStaffImageUrl('laracastrostaff.png'),
    mariaperomingostaff: getStaffImageUrl('mariaperomingostaff.png'),
    default: getStaffImageUrl('default.png'),
  };
  
  export default playerImageMap;