import React from 'react';
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import { FaQuestionCircle, FaVoteYea } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { IoAnalyticsSharp } from "react-icons/io5";
import { MdOutlineDisplaySettings } from "react-icons/md";

const CookiePolicy = () => {
    const isMobile = useMediaQuery("(max-width: 600px)");
    const classes = useStyles();

    return (
        <div className={isMobile ? classes.cookiePolicyContainerMobile : classes.cookiePolicyContainer}>
            <h1>Política de Cookies</h1>
            <p>Esta es la política de cookies de nuestro sitio web.</p>
            <h2><FaQuestionCircle size={16}/> ¿Qué son las cookies?</h2>
            <p>Las cookies son pequeños archivos de texto que se almacenan en su navegador cuando visita sitios web. Se utilizan ampliamente para que los sitios web funcionen o funcionen de manera más eficiente, así como para proporcionar información a los propietarios del sitio.</p>
            <h2><FaGear size={16}/> ¿Cómo utilizamos las cookies?</h2>
            <p>Utilizamos cookies para varios propósitos, incluyendo:</p>
            <ul>
                <li>Para recordar sus preferencias de cookies.</li>
                <li>Para realizar un seguimiento de las estadísticas de uso del sitio web.</li>
                <li>Para personalizar el contenido y los anuncios que ve en nuestro sitio web y en otros sitios.</li>
                <li>Para recordar qué dispositivos han votado en las votaciones.</li>
            </ul>
            <h2><IoAnalyticsSharp size={18}/> Seguimiento de visitas con Vercel Analytics</h2>
            <p>Utilizamos Vercel Analytics para llevar un seguimiento de las visitas a nuestra página. Vercel Analytics no recopila información privada y todos los datos son anónimos. Se utilizan únicamente para obtener estadísticas de uso del sitio web y mejorar nuestra plataforma.</p>
            <h2><FaVoteYea size={18}/> Recopilación de información sobre la participación en las Votaciones</h2>
            <p>Utilizamos cookies para registrar si has participado en nuestra encuesta utilizando el almacenamiento local del navegador. Esta información nos permite gestionar de manera eficiente la participación en la encuesta y mejorar la experiencia del usuario. Tu privacidad es una prioridad para nosotros y garantizamos que cualquier dato recopilado se utilizará exclusivamente con este propósito y de acuerdo con nuestra política de privacidad.</p>
            <h2><MdOutlineDisplaySettings size={18}/> ¿Cómo puede controlar las cookies?</h2>
            <p>Puede controlar y/o eliminar las cookies según lo desee. Para obtener más información, consulte aboutcookies.org. Puede eliminar todas las cookies ya almacenadas en su ordenador y configurar la mayoría de los navegadores para que no acepten cookies. Sin embargo, si lo hace, es posible que tenga que ajustar manualmente algunas preferencias cada vez que visite un sitio, y algunas características y servicios pueden no funcionar correctamente.</p>
        </div>
    );
};

export default CookiePolicy;
