import useStyles from '../../styles/styles';
import loadingGif from "../../images/spinninglogo.gif";
import React, { useState, useEffect } from "react";

const Loading = ({ targetDate }) => {
  const classes = useStyles();

  const [isGifLoaded, setIsGifLoaded] = useState(false);
  const [countdownDays, setCountdownDays] = useState(0);
  const [countdownHours, setCountdownHours] = useState(0);
  const [countdownMinutes, setCountdownMinutes] = useState(0);
  const [countdownSeconds, setCountdownSeconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const differenceInMilliseconds = targetDate - now;

      const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      const hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

      setCountdownDays(days);
      setCountdownHours(hours);
      setCountdownMinutes(minutes);
      setCountdownSeconds(seconds);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGifLoad = () => {
    setIsGifLoaded(true);
  };

  return (
    <div className={classes.loading}>
        <img src={loadingGif} alt="Gif" className={classes.loadingImage} onLoad={handleGifLoad} />
        <div className={classes.countdown}>
          Faltan {countdownDays} d {countdownHours} h {countdownMinutes} min y {countdownSeconds} seg
        </div>
    </div>
  );
};

export default Loading;
