import React from "react";
import { GiGrab } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import playerImageMap from "../../maps/playerImageMap";
import { FaBasketballBall } from "react-icons/fa";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";

function VotingNomineeBasketball({ nominee, matchStat, handleInputChange }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  return (
    <div
      key={nominee.idNominee}
      className={
        isMobile
          ? classes.votingDisplayNomineesEntryMobile
          : classes.votingDisplayNomineesEntry
      }
    >
      <div
        className={
          isMobile
            ? classes.votingDisplayNomineesEntryImageMobile
            : classes.votingDisplayNomineesEntryImage
        }
      >
        <img
          src={
            playerImageMap[nominee.player.imageLocator] ||
            playerImageMap["default"]
          }
          alt=""
        />
      </div>
      <div
        className={
          isMobile
            ? classes.votingDisplayNomineesEntryDetailsMobile
            : classes.votingDisplayNomineesEntryDetails
        }
      >
        {/* Nombre del jugador */}
        <h3>
          {nominee.player.person.name + " " + nominee.player.person.surname}
        </h3>
        <h4>{nominee.player.number + " " + nominee.player.position}</h4>

        {/* Estadísticas del partido */}
        {matchStat && (
          <div
            className={
              isMobile
                ? classes.votingDisplayNomineesEntryDetailsStatsContainerMobile
                : classes.votingDisplayNomineesEntryDetailsStatsContainer
            }
          >
            {/* PUNTOS */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconBasketballMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIconBasketball
                }
              >
                PTS.
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.points}
              </div>
            </div>

            {/* REBOTES */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconBasketballMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIconBasketball
                }
              >
                REB.
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.rebounds}
              </div>
            </div>

            {/* ASISTENCIAS */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconBasketballMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIconBasketball
                }
              >
                AST.
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.assists}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          isMobile
            ? classes.votingDisplayNomineesEntryScoreContainerMobile
            : classes.votingDisplayNomineesEntryScoreContainer
        }
      >
        <span>PUNTOS</span>
        <input
          id={"player" + nominee.player.idPlayer}
          className={classes.votingDisplayNomineesScore}
          onChange={(e) => handleInputChange(e)}
          pattern="\d*"
          title="Por favor, ingrese solo números"
        ></input>
      </div>
    </div>
  );
}

export default VotingNomineeBasketball;
