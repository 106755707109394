import React, { useState, useEffect } from 'react';
import { Box, Avatar } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import { FaTshirt, FaCalendarCheck, FaBullhorn } from "react-icons/fa";
import kitsMap from '../../maps/kitsMap';
import imageMap from '../../maps/imageMap';
import staffMap from '../../maps/staffMap';

function getResult(match, equipoId) {
    // Obtener los equipos local y visitante
    const { localTeam, awayTeam } = match;
  
    // Comprobar si el equipo está en el partido
    const esLocal = localTeam.idTeam === equipoId;
    const esVisitante = awayTeam.idTeam === equipoId;
  
    // Si no está en el partido, devolver "No juega"
    if (!esLocal && !esVisitante) {
      return "No juega";
    }
  
    // Obtener los resultados
    const golesLocal = match.resultLocal;
    const golesVisitante = match.resultAway;
  
    // Comparar los resultados y determinar el resultado
    let resultado;
    if (golesLocal > golesVisitante) {
      resultado = esLocal ? "VICTORIA" : "DERROTA";
    } else if (golesLocal < golesVisitante) {
      resultado = esLocal ? "DERROTA" : "VICTORIA";
    } else {
      resultado = "EMPATE";
    }
  
    // Devolver el resultado
    return resultado;
}

function getRival(match, equipoId) {
  
    // Si el equipo está en el partido, devolver el badgeLocator del equipo contrario
    if (match.localTeam.idTeam === equipoId) {
      return match.awayTeam;
    } else if (match.awayTeam.idTeam === equipoId) {
      return match.localTeam;
    }
  
    return null;
  }
  

const TeamDisplayDetails = ({ team, matches, staff}) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const styleCenterImage = {
        margin: '0 auto',
        height: '90%',
        width: '90%',
    }

    const lastMatches = matches.slice(-5);

    const matchesOrdered = lastMatches.sort((a, b) => {
        return b.matchweek - a.matchweek;
    });

    return (
        <div className={isMobile ? classes.teamDisplayDetailsMobile : classes.teamDisplayDetails}>
            <div className={isMobile ? classes.teamDisplayDetailsTitleMobile : classes.teamDisplayDetailsTitle}>
                <FaTshirt size={20}/> Equipaciones
            </div>
            {team.sport === 'football' && 
                <div className={isMobile ? classes.teamDisplayDetailsKitsMobile : classes.teamDisplayDetailsKits}>
                    <div className={classes.teamDisplayDetailsKit}>
                        <div className={isMobile ? classes.teamDisplayDetailsKitImageMobile : classes.teamDisplayDetailsKitImage}>
                            <img src={kitsMap["footballhome"] || kitsMap["default"]} alt="Equipación  local" className={classes.teamDisplayDetailsKitImageSource}/>
                        </div>
                        <div className={classes.teamDisplayDetailsKitName}>
                            Local
                        </div>
                    </div>
                    <div className={classes.teamDisplayDetailsKit}>
                        <div className={isMobile ? classes.teamDisplayDetailsKitImageMobile : classes.teamDisplayDetailsKitImage}>
                            <img src={kitsMap["footballaway"] || kitsMap["default"]} alt="Equipación  visitante" className={classes.teamDisplayDetailsKitImageSource}/>
                        </div>
                        <div className={classes.teamDisplayDetailsKitName}>
                            Visitante
                        </div>
                    </div>
                    <div className={classes.teamDisplayDetailsKit}>
                    <div className={isMobile ? classes.teamDisplayDetailsKitImageMobile : classes.teamDisplayDetailsKitImage}>
                            <img src={kitsMap["footballgoalkeeperhome"] || kitsMap["default"]} alt="Equipación  local de portero" className={classes.teamDisplayDetailsKitImageSource}/>
                        </div>
                        <div className={classes.teamDisplayDetailsKitName}>
                            Portero
                        </div>
                    </div>
                </div>
            }
            {team.sport === 'basketball' && 
                <div className={isMobile ? classes.teamDisplayDetailsKitsMobile : classes.teamDisplayDetailsKits}>
                    <div className={classes.teamDisplayDetailsKit}>
                        <div className={isMobile ? classes.teamDisplayDetailsKitImageMobile : classes.teamDisplayDetailsKitImage}>
                            <img src={kitsMap["basketballhome"] || kitsMap["default"]} alt="Equipación  local" className={classes.teamDisplayDetailsKitImageSource}/>
                        </div>
                        <div className={classes.teamDisplayDetailsKitName}>
                            Local
                        </div>
                    </div>
                </div>
            }
            <div className={isMobile ? classes.teamDisplayDetailsTitleMobile : classes.teamDisplayDetailsTitle}>
                <FaCalendarCheck size={20}/> Últimos resultados
            </div>
            <div className={isMobile ? classes.teamDisplayDetailsLastMatchesMobile : classes.teamDisplayDetailsLastMatches}>
                {lastMatches.map((match, index) => (
                    <div className={classes.teamDisplayDetailsLastMatchesEntry}>
                        <div className={classes.teamDisplayDetailsLastMatchesEntryImage}>
                            <Avatar src={imageMap[getRival(match, team.idTeam).badgeLocator] || imageMap["default"]} style={styleCenterImage}/>
                        </div>
                        <div className={classes.teamDisplayDetailsLastMatchesEntryResult}>
                            {getResult(match, team.idTeam) === 'VICTORIA' && 
                                <div className={classes.teamDisplayDetailsLastMatchesEntryResultVictory}>
                                    VICTORIA
                                </div>
                            }
                            {getResult(match, team.idTeam) === 'DERROTA' && 
                                <div className={classes.teamDisplayDetailsLastMatchesEntryResultLoss}>
                                    DERROTA
                                </div>
                            }
                            {getResult(match, team.idTeam) === 'EMPATE' && 
                                <div className={classes.teamDisplayDetailsLastMatchesEntryResultDraw}>
                                    EMPATE
                                </div>
                            }
                        </div>
                        <div className={classes.teamDisplayDetailsLastMatchesEntryScore}>
                            {match.resultLocal} - {match.resultAway}
                        </div>
                        <div className={classes.teamDisplayDetailsLastMatchesEntryVsTeam}>
                            {getRival(match, team.idTeam).name}
                        </div>
                        <div className={classes.teamDisplayDetailsLastMatchesEntryDate}>
                            {match.date}
                        </div>
                    </div>
                ))}
            </div>
            <div className={isMobile ? classes.teamDisplayDetailsTitleMobile : classes.teamDisplayDetailsTitle}>
                <FaBullhorn size={20}/> Cuerpo técnico
            </div>
            <div className={isMobile ? classes.teamDisplayDetailsStaffMobile : classes.teamDisplayDetailsStaff}>
                {staff.map((staff, index) => (
                    <div className={classes.teamDisplayDetailsStaffEntry}>
                        <img src={staffMap[staff.imageLocator] || staffMap['default']} className={classes.teamDisplayDetailsStaffEntryImage}/>
                        <div  className={classes.teamDisplayDetailsStaffEntryName}>
                            {staff.person.name + ' ' + staff.person.surname}
                        </div>
                        <div  className={classes.teamDisplayDetailsStaffEntryRole}>
                            {staff.role}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamDisplayDetails;