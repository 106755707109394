import React from 'react';
import { Link } from 'react-router-dom';
import {useMediaQuery} from '@mui/material';
import { Card, CardContent, CardMedia, Typography} from '@mui/material';
import useStyles from '../../styles/styles';
import imageMap from '../../maps/imageMap.js';
import iconMap from '../../maps/iconMap.js';
import ForwardIcon from '@mui/icons-material/Forward';
import InfoIcon from '@mui/icons-material/Info';
import backgroundImageMap from '../../maps/backgroundImageMap.js';

const TeamInfo = ({ team }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const imagePath = imageMap[team.badgeLocator] || imageMap["default"];
    const icon = iconMap[team.sport];

    const getBackgroundURL = (value) => {
      return backgroundImageMap[value] || backgroundImageMap["playerBackground"];
    };

    const headerBackground = getBackgroundURL(team.sport);

    const backgroundHeaderStyle = {
        /* Estilos para el playerDisplayHeader si los tienes */
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: 'cover',
    };

  return (
    <Link to={"/team/" + team.idTeam} className={isMobile ? classes.teamListItemContainerMobile : classes.teamListItemContainer}>
      <div className={isMobile ? classes.teamListItemBadgeMobile : classes.teamListItemBadge} style={backgroundHeaderStyle}>
        <img src={imagePath} alt={"Escudo de " + team.name} />
      </div>
      <div className={isMobile ? classes.teamListItemInfoMobile : classes.teamListItemInfo}>
      <Typography variant='h3'>
        {team.name} 
        {team.sport === 'football' && ' C.F.'}
        {team.sport === 'basketball' && ' C.B.'}
      </Typography>
        {icon}
      </div>
    </Link>
  );
};

export default TeamInfo;