import React, { useEffect } from "react";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import otherImageMap from "../../maps/otherImageMap";

const CookiesNecessaryNonAccepted = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Comprobar si se han aceptado las cookies
      const hasAcceptedCookies = sessionStorage.getItem("cookiesAccepted");

      // Si se han aceptado las cookies, recargar la página
      if (hasAcceptedCookies) {
        window.location.reload();
      }
    }, 3000); // Intervalo de comprobación cada 5 segundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []); // Se ejecuta solo una vez al renderizar el componente

  return (
    <div className={isMobile ? classes.cookiesNecessaryNonAcceptedContainerMobile : classes.cookiesNecessaryNonAcceptedContainer}>
      <p>Se requiere aceptar las cookies para acceder a este contenido.</p>
      <img src={otherImageMap["cookies"] || otherImageMap["default"]} alt="Cookies" />
    </div>
  );
};

export default CookiesNecessaryNonAccepted;
