import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import CollectionsIcon from '@mui/icons-material/Collections';
import { autoPlay } from 'react-swipeable-views-utils';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImageCarousel = ({ imagenes }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <section className={classes.nextMatches}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <CollectionsIcon /> Galería de imágenes
                </h2>
                <AutoPlaySwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
                  {imagenes.map((imagen, index) => (
                    <div key={index} className={isMobile ? classes.slideMobile : classes.slide}>
                      <img src={imagen} alt={`Imagen ${index + 1}`} className={classes.image} />
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
            </div>
        </section>
  );
};

export default ImageCarousel;
