const getAwardImageUrl = ( awardImageName ) => {
    return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/awards/' + awardImageName;
}

const imageMap = {
    POTG: getAwardImageUrl('potg.png'),
    POTM: getAwardImageUrl('potm.png'),
    POTY: getAwardImageUrl('poty.png'),
    default: getAwardImageUrl('default.png'),
  };
  
  export default imageMap;