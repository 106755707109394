import useStyles from '../../styles/styles';
import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import PageHeader from "../../components/PageHeader";
import TeamListItem from '../../components/teams/TeamListItem';
import Footer from "../../components/Footer";
import Stack from '@mui/material/Stack';

const Teams = () => {

    const [data, setData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        // Realizar una solicitud GET al backend de Spring
        axios.get('/teams/nonrivals')
          .then(response => {
            // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
            setData(response.data);
          })
          .catch(error => {
            // Manejo de errores en caso de que la solicitud falle
            console.error('Error al obtener los datos:', error);
          });
      }, []);

    return (
        <div>
            <PageHeader headerTitle="Nuestros equipos"/>
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap" className={classes.teamsContainer}>
              {data.map(team => (
                  <TeamListItem team={team} />
              ))}
            </Stack>
            <Footer />
        </div>
    );

};

export default Teams;
