import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';

const Hero = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const getTeamImageUrl = ( sponsorLogoImageName ) => {
        return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/team/' + sponsorLogoImageName;
    }

    const classes = useStyles();

    return (
        <Box className={classes.heroBox}>
            <div className={classes.heroOverlay}></div>
            <div className={classes.heroContent}>
                <Grid container spacing={6} className={classes.gridContainer}>
                    <Grid item xs={12} md={7}>
                        <h1 className={isMobile ? classes.heroTitleMobile : classes.heroTitle}>
                            Mad Flowers
                        </h1>
                        <p className={classes.heroSubtitle}>
                            ¡Donde la pasión echa raíces!
                        </p>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <img src={getTeamImageUrl('madflowers.png')} alt="My Team" className={classes.largeImage} />
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
};

export default Hero;