import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import { IoSadOutline } from "react-icons/io5";
import awardImageMap from "../../maps/awardImageMap";

function getMonthFromDate(date) {
    // Convertir la fecha a un objeto Date
    const dateObject = new Date(date);
  
    // Obtener el mes (0-11)
    const month = dateObject.getMonth();
  
    // Array con los nombres de los meses
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    // Retornar el nombre del mes
    return monthNames[month];
}

const PlayerAwards = ({ player }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const [potgAwards, setPotgAwards] = useState([]);
    const [potmAwards, setPotmAwards] = useState([]);
    const [potyAwards, setPotyAwards] = useState([]);
    const [otherAwards, setOtherAwards] = useState([]);

    const areAllListsEmpty = () => {
        return potgAwards.length === 0 && potmAwards.length === 0 && otherAwards.length === 0;
    };

    useEffect(() => {
        // Realizar una solicitud GET al backend de Spring
        
        axios.get('/players/' + player.idPlayer + '/awards')
        .then(response => {
            // Al recibir la respuesta exitosa, filtrar las entradas según el valor de awardType
            const awards = response.data;

            // Filtrar las entradas según el valor de awardType
            const potgAwards = awards.filter(award => award.awardType === 'POTG');
            const potmAwards = awards.filter(award => award.awardType === 'POTM');
            const potyAwards = awards.filter(award => award.awardType === 'POTY');
            const otherAwards = awards.filter(award => award.awardType !== 'POTG' && award.awardType !== 'POTM' && award.awardType !== 'POTY');

            // Establecer los datos filtrados en el estado del componente
            setPotgAwards(potgAwards);
            setPotmAwards(potmAwards);
            setPotyAwards(potyAwards);
            setOtherAwards(otherAwards);
        })
        .catch(error => {
            // Manejo de errores en caso de que la solicitud falle
            console.error('Error al obtener los premios:', error);
        });
        
    }, []);

    return (
        <div className={isMobile ? classes.playerDisplayAwardsContainerMobile : classes.playerDisplayAwardsContainer}>
            {potgAwards.length > 0 && (
                <div className={isMobile ? classes.playerDisplayAwardsSectionMobile : classes.playerDisplayAwardsSection}>
                    <h3>Jugadores del partido</h3>
                    <div className={isMobile ? classes.playerDisplayAwardsSectionContainerMobile : classes.playerDisplayAwardsSectionContainer}>
                        {potgAwards.map((award, index) => (
                            <div key={index} className={isMobile ? classes.playerDisplayAwardsEntryMobile : classes.playerDisplayAwardsEntry}>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryImageMobile : classes.playerDisplayAwardsEntryImage}>
                                    <img src={awardImageMap[award.awardType] || awardImageMap['default']} alt={award.awardType + ' Jornada ' + award.matchweek} />
                                </div>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryDetailsMobile : classes.playerDisplayAwardsEntryDetails}>
                                    <p className={classes.playerDisplayAwardsDetailsTitle}>{award.awardType} Jornada {award.matchweek}</p>
                                    <p>{award.date}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
            )}

            {potmAwards.length > 0 && (
                <div className={isMobile ? classes.playerDisplayAwardsSectionMobile : classes.playerDisplayAwardsSection}>
                    <h3>Jugadores del mes</h3>
                    <div className={isMobile ? classes.playerDisplayAwardsSectionContainerMobile : classes.playerDisplayAwardsSectionContainer}>
                        {potmAwards.map((award, index) => (
                            <div key={index} className={isMobile ? classes.playerDisplayAwardsEntryMobile : classes.playerDisplayAwardsEntry}>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryImageMobile : classes.playerDisplayAwardsEntryImage}>
                                    <img src={awardImageMap[award.awardType] || awardImageMap['default']} alt={award.awardType + ' ' + getMonthFromDate(award.date)} />
                                </div>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryDetailsMobile : classes.playerDisplayAwardsEntryDetails}>
                                    <p className={classes.playerDisplayAwardsDetailsTitle}>{award.awardType + ' ' + getMonthFromDate(award.date)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {potyAwards.length > 0 && (
                <div className={isMobile ? classes.playerDisplayAwardsSectionMobile : classes.playerDisplayAwardsSection}>
                    <h3>Jugadores del mes</h3>
                    <div className={isMobile ? classes.playerDisplayAwardsSectionContainerMobile : classes.playerDisplayAwardsSectionContainer}>
                        {potyAwards.map((award, index) => (
                            <div key={index} className={isMobile ? classes.playerDisplayAwardsEntryMobile : classes.playerDisplayAwardsEntry}>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryImageMobile : classes.playerDisplayAwardsEntryImage}>
                                    <img src={awardImageMap[award.awardType] || awardImageMap['default']} alt={award.awardType} />
                                </div>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryDetailsMobile : classes.playerDisplayAwardsEntryDetails}>
                                    <p className={classes.playerDisplayAwardsDetailsTitle}>{award.awardType + ' ' + getMonthFromDate(award.date)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {otherAwards.length > 0 && (
                <div className={isMobile ? classes.playerDisplayAwardsSectionMobile : classes.playerDisplayAwardsSection}>
                    <h3>Otros premios</h3>
                    <div className={isMobile ? classes.playerDisplayAwardsSectionContainerMobile : classes.playerDisplayAwardsSectionContainer}>
                        {otherAwards.map((award, index) => (
                            <div key={index} className={isMobile ? classes.playerDisplayAwardsEntryMobile : classes.playerDisplayAwardsEntry}>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryImageMobile : classes.playerDisplayAwardsEntryImage}>
                                <img src={awardImageMap[award.awardType] || awardImageMap['default']} alt={award.awardType + ' ' + award.matchweek} />
                                </div>
                                <div className={isMobile ? classes.playerDisplayAwardsEntryDetailsMobile : classes.playerDisplayAwardsEntryDetails}>
                                    <p className={classes.playerDisplayAwardsDetailsTitle}>{award.awardType} Jornada {award.matchweek}</p>
                                    <p>{award.date}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {areAllListsEmpty() && (
                <div className={classes.playerDisplayAwardsEmpty}>
                    <IoSadOutline /> Vaya... no hay nada por aquí de momento.
                </div>
            )}
        </div>
    );
};

export default PlayerAwards;