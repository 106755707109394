import React, { useEffect, useState } from 'react';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import imageMap from '../../maps/imageMap';
import { Table, TableBody, TableCell, TableHead, TableRow, Avatar, Hidden } from '@mui/material';

const PlayerDisplayHistory = ({ matches }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const styleHeader = { 
        fontWeight: 'bold', 
        textTransform: 'uppercase', 
        textAlign: 'center',
        fontSize: '0.7rem'
    };

    const styleCellNormal = { 
        textAlign: 'center',
        fontSize: '0.7rem',
        margin: '0 auto',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const styleCellBold = { 
        textAlign: 'center',
        fontSize: '0.7rem',
        fontWeight: 'bold',
    };

    const styleCenterImage = {
        margin: '0 auto',
    }

    const trimTimeFromDate = (dateTime) => {
        // Separar la cadena de fecha en sus partes de fecha y hora
        const [datePart, timePart] = dateTime.split(" ");
        
        // Separar la parte de la hora en sus componentes de hora, minutos y segundos
        const [hour, minute, second] = timePart.split(":");
        
        // Devolver la fecha junto con la hora y los minutos, sin los segundos
        return `${datePart} ${hour}:${minute}`;
    };

    return (
        <div className={isMobile ? classes.playerDisplayHistoryContainerMobile : classes.playerDisplayHistoryContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={styleHeader}>{isMobile ? 'Jor.' : 'Jornada'}</TableCell>
                        <TableCell style={styleHeader}>{isMobile ? 'Local' : 'Equipo Local'}</TableCell>
                        <TableCell style={styleHeader}>{isMobile ? 'Visitante' : 'Equipo Visitante'}</TableCell>
                        <TableCell style={styleHeader}>{isMobile ? 'Result.' : 'Resultado'}</TableCell>
                        <TableCell style={styleHeader}>Fecha</TableCell>
                        <Hidden smDown>
                            <TableCell style={styleHeader}>Campo</TableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {matches.map((match, index) => (
                        <TableRow key={index}>
                            <TableCell style={styleCellNormal}>{match.matchweek}</TableCell>
                            <TableCell style={styleCellNormal}><Avatar alt={match.localTeam.name} src={imageMap[match.localTeam.badgeLocator]} style={styleCenterImage}/></TableCell>
                            <TableCell style={styleCellNormal}><Avatar alt={match.awayTeam.name} src={imageMap[match.awayTeam.badgeLocator]} style={styleCenterImage}/></TableCell>
                            <TableCell style={styleCellNormal}>{match.resultLocal} - {match.resultAway}</TableCell>
                            <TableCell style={styleCellNormal}>{trimTimeFromDate(match.date)}</TableCell>
                            <Hidden smDown>
                                <TableCell style={styleCellNormal}>{match.stadium}</TableCell>
                            </Hidden>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default PlayerDisplayHistory;