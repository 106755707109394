import React, { useState } from 'react';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import Calendar from 'react-calendar';
import iconMap from '../../maps/iconMap.js';
import 'react-calendar/dist/Calendar.css';
import '../../styles/CalendarComponent.css';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function formatDate(dateString) {
  // Convertir la cadena a un objeto Date
  const date = new Date(dateString);

  // Obtener los componentes de la fecha
  const month = date.getMonth() + 1; // Meses van de 0 a 11, por eso se suma 1
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Formatear la fecha al formato americano
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}

function formatDateToAmerican(dateString) {
  const [dayString, monthString, yearString] = dateString.split("/");
  const [yearString2, timeString] = yearString.split(" ");
  const month = parseInt(monthString, 10) - 1;
  const date = new Date(yearString2, month, dayString);

  if (date.getTime()) {
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  } else {
    // Handle invalid date scenario
    return "Invalid Date";
  }
}

function CalendarComponent({ matches, sport }) {
  
  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles();

  const [markedDates, setMarkedDates] = useState([]);

  const markedDatesArray = matches.map((item) => new Date(formatDateToAmerican(item.date)));

  useState(() => {
    setMarkedDates(markedDatesArray);
  }, []);

  const getTileClassName = ({ date, view }) => {
    if (
      view === 'month' &&
      markedDates.some(
        (markedDate) =>
          markedDate.getDate() === date.getDate() &&
          markedDate.getMonth() === date.getMonth() &&
          markedDate.getFullYear() === date.getFullYear()
      )
    ) {
      return 'marked-date'; // Clase CSS para fechas marcadas
    }
    return null;
  };

  const icon = iconMap[sport];

  return (
    <div className={isMobile ? classes.teamDisplayCalendarMobile : classes.teamDisplayCalendar}>
      <Calendar
        value={new Date()}
        prev2Label={<KeyboardDoubleArrowLeftIcon />} // Texto o JSX para la flecha de retroceso doble
        prevLabel={<KeyboardArrowLeftIcon />}   // Texto o JSX para la flecha de retroceso
        nextLabel={<KeyboardArrowRightIcon />}   // Texto o JSX para la flecha de avance
        next2Label={<KeyboardDoubleArrowRightIcon />} // Texto o JSX para la flecha de avance doble
        style={{ aspectRatio: '1' }}
        tileClassName={getTileClassName}
        tileContent={({ date, view }) =>
          view === 'month' &&
          markedDates.some(
            (markedDate) =>
              markedDate.getDate() === date.getDate() &&
              markedDate.getMonth() === date.getMonth() &&
              markedDate.getFullYear() === date.getFullYear()
          ) && (
            <div>
              {icon}
            </div>
          )
        }
      />
    </div>
  );
}

export default CalendarComponent;
