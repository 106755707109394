const getPlayerImageUrl = ( playerImageName ) => {
  return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/playerBody/' + playerImageName;
}


const playerImageMap = {
  alejandrolopez: getPlayerImageUrl('alejandrolopez.png'),
  alejandrovalle: getPlayerImageUrl('alejandrovalle.png'),
  alvaronevado: getPlayerImageUrl('alvaronevado.png'),
  oscarmunoz: getPlayerImageUrl('oscarmunoz.png'),
  pabloiglesias: getPlayerImageUrl('pabloiglesias.png'),
  alejandronevado: getPlayerImageUrl('alejandronevado.png'),
  sergiomunoz: getPlayerImageUrl('sergiomunoz.png'),
  ikeralday: getPlayerImageUrl('ikeralday.png'),
  adrianlopez: getPlayerImageUrl('adrianlopez.png'),
  angelmartinez: getPlayerImageUrl('angelmartinez.png'),
  alvaronevado: getPlayerImageUrl('alvaronevado.png'),
  jorgebarrero: getPlayerImageUrl('jorgebarrero.png'),
  jorgepradana: getPlayerImageUrl('jorgepradana.png'),
  miguelcarmona: getPlayerImageUrl('miguelcarmona.png'),
  pablomartinez: getPlayerImageUrl('pablomartinez.png'),
  cesarnavalon: getPlayerImageUrl('cesarnavalon.png'),
  jorgenavarro: getPlayerImageUrl('jorgenavarro.png'),
  raulperona: getPlayerImageUrl('raulperona.png'),
  mikelazofra: getPlayerImageUrl('mikelazofra.png'),
  darioruiz: getPlayerImageUrl('darioruiz.png'),
  gonzalovillena: getPlayerImageUrl('gonzalovillena.png'),
  alvarodeporras: getPlayerImageUrl('alvarodeporras.png'),
  danielbollo: getPlayerImageUrl('danielbollo.png'),
  alvarocortes: getPlayerImageUrl('alvarocortes.png'),
  default: getPlayerImageUrl('default.png'),
};

export default playerImageMap;
