import React, { useState, useContext, useEffect } from "react";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import { FaCookieBite, FaInfoCircle } from "react-icons/fa";

// Crear un nuevo contexto para el consentimiento de cookies
const CookieConsentContext = React.createContext();

const CookieConsentProvider = ({ children }) => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useEffect(() => {
    // Verificar si el usuario ya ha aceptado las cookies
    const cookiesAccepted = sessionStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setAcceptedCookies(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Almacenar en la sesión que el usuario ha aceptado las cookies
    sessionStorage.setItem("cookiesAccepted", "true");
    setAcceptedCookies(true);
  };

  return (
    <CookieConsentContext.Provider value={{ acceptedCookies, handleAcceptCookies }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

const useCookieConsent = () => {
  return useContext(CookieConsentContext);
};

const CookieConsent = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const { acceptedCookies, handleAcceptCookies } = useCookieConsent();

  if (acceptedCookies) {
    return null; // No mostrar el aviso si las cookies ya han sido aceptadas
  }

  return (
    <div className={classes.cookieNotice}>
      <div className={classes.cookieNoticeContainer}>
        <h2>
          <FaCookieBite size={14} /> Aviso de cookies
        </h2>
        <p>
          Este sitio web usa cookies para mejorar tu experiencia. Al navegar por
          este sitio, aceptas el uso de cookies.
        </p>
        <div className={classes.cookieNoticeButtons}>
          <button onClick={handleAcceptCookies}>Aceptar cookies</button>
          {/* Puedes añadir un enlace a una página de política de cookies */}
          <a href="/cookies"><FaInfoCircle size={12}/> Más información</a>
        </div>
      </div>
    </div>
  );
};

export { CookieConsentProvider, useCookieConsent, CookieConsent };
