import React from "react";
import { FaTrophy } from "react-icons/fa";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import playerImageMap from "../../maps/playerImageMap";

function VotingDisplayResult({ voting, winner }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  return (
    <div className={classes.votingDisplayResult}>
      <h3>
        <FaTrophy size={18} /> Ganador de la votación <FaTrophy size={18} />
      </h3>
      <div className={classes.votingDisplayResultPlayerDisplay}>
        <div
          className={
            isMobile
              ? classes.votingDisplayResultPlayerDisplayImageMobile
              : classes.votingDisplayResultPlayerDisplayImage
          }
        >
          <img
            src={
              playerImageMap[winner.imageLocator] || playerImageMap["default"]
            }
            alt=""
          />
        </div>
        <div
          className={
            isMobile
              ? classes.votingDisplayResultPlayerDisplayDetailsMobile
              : classes.votingDisplayResultPlayerDisplayDetails
          }
        >
          <h4>{winner.person.name + " " + winner.person.surname}</h4>
          <p><span>{winner.number} {winner.position}</span></p>
        </div>
      </div>
    </div>
  );
}

export default VotingDisplayResult;
