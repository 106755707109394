import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import imageMap from "../../maps/imageMap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Hidden,
} from "@mui/material";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import axios from "../../axiosConfig"; // Importa el objeto Axios configurado
import { FaArrowUp, FaArrowDown, FaEquals } from "react-icons/fa";

function translateProgress(progress) {
  const greenColor = { color: "green" };
  const redColor = { color: "red" };

  if (progress === ">") {
    return <FaArrowUp style={greenColor} />;
  } else if (progress === "<") {
    return <FaArrowDown style={redColor} />;
  } else {
    return <FaEquals />;
  }
}

const TeamDisplayStandings = ({ team }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const [standings, setStandings] = useState([]);

  useEffect(() => {
    // Realizar una solicitud GET al backend de Spring
    if (team.sport === "football") {
      axios
        .get("/teams/" + team.idTeam + "/footballstandings")
        .then((response) => {
          // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
          setStandings(response.data);
        })
        .catch((error) => {
          // Manejo de errores en caso de que la solicitud falle
          console.error("Error al obtener las clasificaciones:", error);
        });
    } else if (team.sport === "basketball") {
      axios
        .get("/teams/" + team.idTeam + "/basketballstandings")
        .then((response) => {
          // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
          setStandings(response.data);
        })
        .catch((error) => {
          // Manejo de errores en caso de que la solicitud falle
          console.error("Error al obtener las clasificaciones:", error);
        });
    }
  }, []);

  const styleHeader = {
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "0.9rem",
  };

  const styleCellNormal = {
    textAlign: "center",
    fontSize: "0.9rem",
    margin: "0 auto",
    alignItems: "center",
    justifyContent: "center",
  };

  const styleCellBold = {
    textAlign: "center",
    fontSize: "0.9rem",
    fontWeight: "bold",
  };

  const styleCenterImage = {
    margin: "0 auto",
  };

  return (
    <Box
      className={
        isMobile ? classes.teamDisplaySquadMobile : classes.teamDisplaySquad
      }
    >
      <Table
        className={
          isMobile
            ? classes.teamDisplaySquadTableMobile
            : classes.teamDisplaySquadTable
        }
      >
        <TableHead>
          <TableRow>
            <TableCell style={styleHeader}>Posición</TableCell>
            <TableCell style={styleHeader}>Equipo</TableCell>
            <Hidden smDown>
              <TableCell style={styleHeader}>Jugados</TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell style={styleHeader}>Victorias</TableCell>
              {team.sport === "football" && (
                <TableCell style={styleHeader}>Empates</TableCell>
              )}
              <TableCell style={styleHeader}>Derrotas</TableCell>
              {team.sport === "football" && (
                <TableCell style={styleHeader}>GF</TableCell>
              )}
              {team.sport === "basketball" && (
                <TableCell style={styleHeader}>PF</TableCell>
              )}
              {team.sport === "football" && (
                <TableCell style={styleHeader}>GC</TableCell>
              )}
              {team.sport === "basketball" && (
                <TableCell style={styleHeader}>PC</TableCell>
              )}
            </Hidden>
            <Hidden smDown>
              {team.sport === "football" && (
                <TableCell style={styleHeader}>DG</TableCell>
              )}
              {team.sport === "basketball" && (
                <TableCell style={styleHeader}>DP</TableCell>
              )}
            </Hidden>
            <Hidden mdDown>
              {team.sport === "football" && (
                <TableCell style={styleHeader}>GF/GC</TableCell>
              )}
              {team.sport === "basketball" && (
                <TableCell style={styleHeader}>PF/PC</TableCell>
              )}
            </Hidden>
            <TableCell style={styleHeader}>Puntos</TableCell>
            <TableCell style={styleHeader}>Progreso</TableCell>
          </TableRow>
        </TableHead>
        {team.sport === "football" && (
          <TableBody>
            {standings.map((standing, index) => (
              <TableRow key={index}>
                <TableCell style={styleCellBold}>{index + 1}</TableCell>
                <TableCell>
                  <Avatar
                    alt=""
                    src={
                      imageMap[standing.team.badgeLocator] ||
                      imageMap["default"]
                    }
                    title={standing.team.name}
                    style={styleCenterImage}
                  />
                </TableCell>
                <Hidden smDown>
                  <TableCell style={styleCellNormal}>
                    {standing.juegos}
                  </TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell style={styleCellNormal}>
                    {standing.victorias}
                  </TableCell>
                  <TableCell style={styleCellNormal}>
                    {standing.empates}
                  </TableCell>
                  <TableCell style={styleCellNormal}>
                    {standing.derrotas}
                  </TableCell>
                  <TableCell style={styleCellNormal}>
                    {standing.golesAFavor}
                  </TableCell>
                  <TableCell style={styleCellNormal}>
                    {standing.golesEnContra}
                  </TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell style={styleCellNormal}>
                    {standing.golesAFavor - standing.golesEnContra}
                  </TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell style={styleCellNormal}>
                    {(standing.golesAFavor / standing.golesEnContra).toFixed(2)}
                  </TableCell>
                </Hidden>
                <TableCell style={styleCellNormal}>{standing.puntos}</TableCell>
                <TableCell style={styleCellNormal}>
                  {translateProgress(standing.progreso)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        {team.sport === "basketball" && (
            <TableBody>
              {standings.map((standing, index) => (
                <TableRow key={index}>
                  <TableCell style={styleCellBold}>{index + 1}</TableCell>
                  <TableCell>
                    <Avatar
                      alt=""
                      src={
                        imageMap[standing.team.badgeLocator] ||
                        imageMap["default"]
                      }
                      title={standing.team.name}
                      style={styleCenterImage}
                    />
                  </TableCell>
                  <Hidden smDown>
                    <TableCell style={styleCellNormal}>
                      {standing.gamesPlayed}
                    </TableCell>
                  </Hidden>
                  <Hidden mdDown>
                    <TableCell style={styleCellNormal}>
                      {standing.wins}
                    </TableCell>
                    <TableCell style={styleCellNormal}>
                      {standing.losses}
                    </TableCell>
                    <TableCell style={styleCellNormal}>
                      {standing.pointsScored}
                    </TableCell>
                    <TableCell style={styleCellNormal}>
                      {standing.pointsAgainst}
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell style={styleCellNormal}>
                      {standing.pointsScored - standing.pointsAgainst}
                    </TableCell>
                  </Hidden>
                  <Hidden mdDown>
                    <TableCell style={styleCellNormal}>
                      {(standing.pointsScored / standing.pointsAgainst).toFixed(2)}
                    </TableCell>
                  </Hidden>
                  <TableCell style={styleCellNormal}>{standing.points}</TableCell>
                  <TableCell style={styleCellNormal}>
                    {translateProgress(standing.progreso)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
      </Table>
    </Box>
  );
};

export default TeamDisplayStandings;
