import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const Loading = ({ text = 'Cargando...' }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <CircularProgress color="primary" size={50} style={{ color: 'white' }} />
            <Typography variant="h6" color="textSecondary" style={{ marginTop: 10 }}>
                {text}
            </Typography>
        </Box>
    );
};

export default Loading;
