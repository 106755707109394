import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts'; // Importar todos los símbolos de echarts
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';

const BasicRatingsGoalkeeperChart = ({ ratings }) => {
  const chartRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles();

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const options = {
      tooltip: {},
      radar: {
        shape: 'circle',
        indicator: [
          { name: 'VELOCIDAD' },
          { name: 'ESTIRADA' },
          { name: 'PARADAS' },
          { name: 'POSICIONAMIENTO' },
          { name: 'REFLEJOS' },
          { name: 'SAQUE' },
        ],
        splitLine: { // Configuración de las líneas divisorias del radar
            lineStyle: {
                opacity: 0 // Hacer las líneas divisorias transparentes
            }
        },
        splitArea: {
            show: false
        },
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: ratings,
            areaStyle: { opacity: 0.5 },
            name: 'Ratings del Jugador',
            itemStyle: {
              color: 'rgba(204, 127, 204, 0.8)' // Por ejemplo: rojo semitransparente
            }
          },
        ],
      }],
    };

    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [ratings]);

  return <div ref={chartRef} className={isMobile ? classes.generalRatingsMobile : classes.generalRatings} />;
};

export default BasicRatingsGoalkeeperChart;
