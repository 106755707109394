import React, { useState, useEffect } from "react";
import { MdSportsSoccer } from "react-icons/md";
import { GiBarefoot } from "react-icons/gi";
import { TbRectangleVerticalFilled } from "react-icons/tb";
import { FaCalendarDay } from "react-icons/fa";
import playerImageMap from "../../maps/playerImageMap";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import axios from "../../axiosConfig";

function VotingNonGameNomineeFootball({
  nominee,
  voting,
  season,
  handleInputChange,
}) {
  const isMobile = useMediaQuery("(max-width: 720px)");
  const classes = useStyles();

  const [matchStat, setMatchStat] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isStatsLoaded, setIsStatsLoaded] = useState(false);

  useEffect(() => {
    console.log(season);
    if (!isStatsLoaded && !season) {
      axios
        .get("/players/" + nominee.player.idPlayer + "/statsByMonthAndYear/", {
          params: {
            date: voting.match.date,
          },
        })
        .then((response) => {
          // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
          setMatchStat(response.data);
          setIsStatsLoaded(true);
        })
        .catch((error) => {
          // Manejo de errores en caso de que la solicitud falle
          console.error("Error al obtener los datos de la nominacion:", error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsStatsLoaded(true);
        });
    } else if (!isStatsLoaded && season) {
      axios
        .get("/players/" + nominee.player.idPlayer + "/stats/" + season)
        .then((response) => {
          // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
          setMatchStat(response.data);
          setIsStatsLoaded(true);
        })
        .catch((error) => {
          // Manejo de errores en caso de que la solicitud falle
          console.error("Error al obtener los datos de la nominacion:", error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsStatsLoaded(true);
        });
    }
  });

  return (
    <div
      key={nominee.idNominee}
      className={
        isMobile
          ? classes.votingDisplayNomineesEntryMobile
          : classes.votingDisplayNomineesEntry
      }
    >
      <div
        className={
          isMobile
            ? classes.votingDisplayNomineesEntryImageMobile
            : classes.votingDisplayNomineesEntryImage
        }
      >
        <img
          src={
            playerImageMap[nominee.player.imageLocator] ||
            playerImageMap["default"]
          }
          alt=""
        />
      </div>
      <div
        className={
          isMobile
            ? classes.votingDisplayNomineesEntryDetailsMobile
            : classes.votingDisplayNomineesEntryDetails
        }
      >
        {/* Nombre del jugador */}
        <h3>
          {nominee.player.person.name + " " + nominee.player.person.surname}
        </h3>
        <h4>{nominee.player.number + " " + nominee.player.position}</h4>

        {/* Estadísticas del partido */}
        {matchStat && (
          <div
            className={
              isMobile
                ? classes.votingDisplayNomineesEntryDetailsStatsContainerMobile
                : classes.votingDisplayNomineesEntryDetailsStatsContainer
            }
          >
            {/* GOLES */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIcon
                }
              >
                <MdSportsSoccer />
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.goals}
              </div>
            </div>

            {/* ASISTENCIAS */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIcon
                }
              >
                <GiBarefoot />
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.assists}
              </div>
            </div>

            {/* PARTIDOS JUGADOS */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIcon
                }
              >
                <FaCalendarDay />
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.numberOfMatches}
              </div>
            </div>

            {/* TARJETAS AMARILLAS */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIcon
                }
              >
                <TbRectangleVerticalFilled style={{ color: "yellow" }} />
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.yellowCards}
              </div>
            </div>

            {/* TARJETAS ROJAS */}
            <div
              className={
                isMobile
                  ? classes.votingDisplayNomineesEntryDetailsStatEntryMobile
                  : classes.votingDisplayNomineesEntryDetailsStatEntry
              }
            >
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryIconMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryIcon
                }
              >
                <TbRectangleVerticalFilled style={{ color: "red" }} />
              </div>
              <div
                className={
                  isMobile
                    ? classes.votingDisplayNomineesEntryDetailsStatEntryValueMobile
                    : classes.votingDisplayNomineesEntryDetailsStatEntryValue
                }
              >
                {matchStat.yellowCards}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          isMobile
            ? classes.votingDisplayNomineesEntryScoreContainerMobile
            : classes.votingDisplayNomineesEntryScoreContainer
        }
      >
        <span>PUNTOS</span>
        <input
          id={"player" + nominee.player.idPlayer}
          className={classes.votingDisplayNomineesScore}
          onChange={(e) => handleInputChange(e)}
          pattern="\d*"
          title="Por favor, ingrese solo números"
        ></input>
      </div>
    </div>
  );
}

export default VotingNonGameNomineeFootball;
