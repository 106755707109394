import { tokens } from "../theme";
import { makeStyles } from '@mui/styles';
import heroBackgroundImg from '../images/heroimage.jpg';

const styles = (theme) => {

  const colors = tokens(theme.palette.mode);

  return {
    // Generales
    homeContainer: {
      width: '100%',
    },
    toolBar: {
      height: '10vh',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      backgroundColor: 'red',
    },
    logo: {
      color: 'blue',
      cursor: 'pointer',
    },
    link: {
      color: "#000"
    },
    menuIcon: {
      color: '#000',
    },
    formContainer: {
      flexGrow: 1,
      padding: '10px',
      maxWidth: '700px',
      margin: '30px auto',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
      },
    },
    form: {
      marginTop: '30px',
    },
    formHeading: {
      textAlign: 'center',
    },
    container : {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 2em',
    },

    // SIDEBAR
    drawerContainer: {
    },

    sidebarMobileButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '1rem',
      marginTop: '1rem',
    },

    // TOPBAR
    topbar: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: colors.menubar[500],
      width: '100%',
      boxShadow: theme.shadows[4],
    },

    // Pie de página
    footer : {
      width: '100%',
      padding: '20px 0',
      textAlign: 'center',
      borderTop: '1px solid',
      borderColor: colors.grey[800],
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      miHeight: '10vh',
      padding: '20px',
      justifyContent: 'center',
      backgroundColor: '#f2f0f1',
      flexDirection: 'column',
    },
    footerContent : {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerText : {
      marginBottom: '10px',
      paddingBottom: '10px',
    },
    socialIcons : {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    socialIcon : {
      color: colors.grey[200],
      margin: '0 1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'backgroundColor 0.3s, transform 0.3s'
    },

    //Loading
    loading: {
      width: '100vw',
      height: '100vh',
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    loadingImage: {
      height: '50%',
    },
    countdown: {
      margin: '20px 0',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'white',
    },

    //COOKIES

    cookieNotice: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: '100',
      background: colors.menubar[500],
    },
    cookieNoticeContainer: {
      padding: '2rem',
      '& h2': {
        marginTop: '0'
      }
    },
    cookieNoticeButtons: {
      '& button': {
        padding: '0.6rem 1.8rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        margin: '0.2rem  0.5rem',
        borderRadius: '2rem',
        background: colors.menubar[500],
        border: '1px solid',
        borderColor: colors.menubar[200],
        color: colors.menubar[200],
        cursor: 'pointer',
      },
      '& a': {
        padding: '0.8rem 1rem',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: '1rem',
        color: colors.menubar[200],
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        lineHeight: '2.3rem'
      }
    },
    cookiePolicyContainerMobile: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem 2rem',
      marginTop: '65px',
      '& h1': {
        textTransform: 'uppercase',
        fontSize: '2rem',
      },
      '& h2': {
        textTransform: 'uppercase',
        fontSize: '1.4rem',
      },
    },
    cookiePolicyContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem 2rem',
      marginTop: '65px',
      '& h1': {
        textTransform: 'uppercase',
        fontSize: '2rem',
      },
      '& h2': {
        textTransform: 'uppercase',
        fontSize: '1.4rem',
      },
    },
    cookiesNecessaryNonAcceptedContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem 2rem',
      marginTop: '65px',
      textAlign: 'center',
      '& p': {
        fontSize: '1.3rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
      '& img': {
        height: '400px',
      },
    },
    cookiesNecessaryNonAcceptedContainerMobile: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem 2rem',
      marginTop: '65px',
      textAlign: 'center',
      '& p': {
        fontSize: '1.3rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
      '& img': {
        height: '300px',
      },
    },
    // Sección Hero - HOME
    heroBox: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      minHeight: '600px',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url(${heroBackgroundImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
    },
    heroOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Ajusta el valor de opacidad (0.5) según tus preferencias
    },
    heroContent: {
      position: 'relative',
      zIndex: 1,
      color: '#fff',
      textAlign: 'center',
      padding: '100px 20px',
    },
    heroButton: {
      textAlign: 'center',
      color: colors.grey[900],
      backgroundColor: colors.grey[200],
    },
    heroTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      paddingBottom: '15px',
      fontSize: '4rem',
    },
    heroTitleMobile: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      paddingBottom: '15px',
      fontSize: '2.4rem',
    },
    heroSubtitle: {
      opacity: '0.7',
      fontSize: '1.5rem',
      marginTop: '0'
    },
    gridContainer: {
      maxWidth: '1300px',
      padding: '50px',
    },

    // Sobre nosotros - HOME
    aboutUsSubtitle: {
      opacity: '0.7',
      paddingBottom: '30px',
      fontSize: '18px',
    },
    aboutUsButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    aboutUsButton: {
      backgroundColor: colors.rose[300],
      color: colors.menubar[500],
      padding: '1rem 3rem',
      borderRadius: '2rem',
      border: 'none',
      fontFamily: 'inherit',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: '1rem',
      fontWeight: 'bold',
      transition: '0.4s',
      marginRight: '2rem',
      textTransform: 'uppercase',
      textDecoration: 'none',
      boxShadow: theme.shadows[4],
    },
    title: {
      fontSize: '1.7rem',
      textTransform: 'uppercase',
    },
    subtitle: {
      opacity: '0.4',
      paddingBottom: '30px',
    },
    largeImage: {
      width: '100%',
    },
    sectionGridContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '500px',
    },
    sectionGridItem: {
      backgroundColor: '#f2f0f1',
      textAlign: 'center',
      padding: '30px',
      width: '200px',
      borderRadius: '10px',
      margin: '10px !important',
    },
    inputField: {
      marginBottom: '20px !important',
    },
    textArea: {
      width: '100%',
      marginBottom: '20px',
      fontSize: '16px',
      padding: '10px',
    }, 

    // Proximos partidos - HOME
    nextMatchContainer: {
      padding: '1rem',
      textAlign: 'center',
    },
    nextMatchRoot: {
      display: 'inline-block',
      textAlign: 'center',
      margin: '20px 0.5em',
      padding: '20px',
      maxWidth: '800px',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      '& p': {
        fontSize: '1rem',
      }
    },
    nextMatchTitle: {
      fontSize: '1.5rem',
      alignItems: 'center',
      gap: '10px',
    },
    teamLogos: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px 0',
      '& img': {
        width: '100px',
        height: '100px',
        objectFit: 'contain',
        borderRadius: '50%',
        boxShadow: theme.shadows[2],
      },
    },
    vsText: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: colors.rose[500],
      margin: '0 1em'
    },
    countdown: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    matchStatus: {
      fontSize: '1.2rem',
      color: theme.palette.text.secondary,
    },
    nextMatchRootMobile: {
      display: 'inline-block',
      maxWidth: '100%',
      textAlign: 'center',
      margin: '20px auto',
      padding: '10px',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      '& p': {
        fontSize: '1rem',
      }
    },
    nextMatchTitleMobile: {
      fontSize: '1.5rem',
      alignItems: 'center',
      gap: '10px',
    },
    teamLogosMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px auto',
      '& img': {
        width: '4rem',
        height: '4rem',
        objectFit: 'contain',
        borderRadius: '50%',
        boxShadow: theme.shadows[2],
      },
    },
    vsTextMobile: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: colors.rose[500],
      margin: '0 1em'
    },
    countdown: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      margin: '0 0.3rem'
    },
    countdownMobile: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '0 0.3em'
    },

    // Últimas noticias - HOME
    articlesContainer: {
      textAlign: 'center',
      margin: '20px 0.5em',
      padding: '20px',
    },
    articlePreview: {
      display: 'inline-block',
      margin: '20px 0.5em',
      border: '1px solid',
      borderColor: colors.grey[800],
      padding: '10px',
      marginBottom: '20px',
      borderRadius: '5px',
      boxShadow: theme.shadows[4],
      maxWidth: '700px',
    },
    articlePreviewTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '10px',
      color: colors.grey[200],
    },
    articlePreviewDescription: {
      fontSize: '1rem',
    },

    // Galería de imágenes - HOME
    slideMobile: {
      height: '300px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    slide: {
      height: '800px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    // Header - TEAMS
    teamsHeader: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      minHeight: '120px',
      maxHeight: '200px',
      marginTop: '65px',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
      padding: '0 2rem',
      fontSize: '2.2rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[400],
    },
    teamsHeaderMobile: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      minHeight: '80px',
      maxHeight: '300px',
      marginTop: '65px',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
      padding: '0 1rem',
      fontSize: '1.8rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[400],
    },
    teamsContainer: {
      maxWidth: '1200px',
      margin: '2rem auto',
      padding: '0 2rem',
    },
    teamListItemContainerMobile: {
      margin: '0.5rem auto',
      display: 'flex',
      border: '1px solid',
      width: '20rem',
      borderRadius: '0.5rem',
      borderColor: colors.grey[700],
      color: colors.grey[100],
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxShadow: theme.shadows[5],
    },
    teamListItemContainer: {
      margin: '0.5rem auto',
      display: 'flex',
      border: '1px solid',
      width: '20rem',
      borderRadius: '0.5rem',
      borderColor: colors.grey[700],
      color: colors.grey[100],
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxShadow: theme.shadows[5],
    },
    teamListItemBadgeMobile: {
      width: '100%',
      height: '7.5rem',
      padding: '0.5rem',
      paddingTop: '1.5rem',
      objectFit: 'cover',
      '& img': {
        height: '100%',
      },
    },
    teamListItemBadge: {
      width: '100%',
      height: '7.5rem',
      padding: '0.5rem',
      paddingTop: '1.5rem',
      objectFit: 'cover',
      '& img': {
        height: '100%',
      },
    },
    teamListItemInfo: {
      width: '100%',
      height: '3.5rem',
      padding: '0.5rem 1.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    teamListItemInfoMobile: {
      width: '100%',
      height: '3.5rem',
      padding: '0.5rem 1.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    teamListItemButton: {
      color: colors.menubar[100],
      display: 'block',
      marginTop: '0.5rem',
      padding: '0.5rem 2rem',
      border: '1px solid',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[1],
      textDecoration: 'none',
      borderRadius: '2rem',
      textAlign: 'center',
    },
    teamsDisplayContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      marginTop: '2rem',
      padding: '0 2em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh', /* Ajusta la altura según tus necesidades */
      justifyContent: 'flexStart', /* Puedes ajustar esto según tu diseño */
      color: colors.fixedText[100],
    },
    teamDisplayHeaderFilter: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[600],
    },
    teamDisplayHeader: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      minHeight: '170px',
      height: '170px',
      marginTop: '65px',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: '1.5rem',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 1.5rem',
    },
    teamDisplayHeaderMobile: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      minHeight: '100px',
      height: '180px',
      marginTop: '65px',
      alignItems: 'center',
      overflow: 'hidden',
      padding: '0 2rem',
      fontSize: '1.5rem',
      textTransform: 'uppercase',
    },
    teamDisplayBadge: {
      height: '100%',
      padding: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
      },
    },
    teamDisplayBadgeMobile: {
      maxWidth: '120px',
      height: '100%',
      padding: '2rem 0',
      display: 'flex',
      '& img': {
        height: '100%',
      },
    },
    teamDisplayInfo: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flex: 1,
      textTransform: 'none',
      padding: '0 1rem',
      justifyContent: 'center',
    },
    teamDisplayInfoMobile: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flex: 1,
      textTransform: 'none',
      padding: '0 1rem',
      justifyContent: 'center',
    },
    teamDisplayInfoTitle: {
      fontSize: '2.8rem',
      lineHeight: '2.8rem',
      fontWeight: 'bold',
      margin: '0',
      marginBottom: '0.5rem',
    },
    teamDisplayInfoTitleMobile: {
      fontSize: '2rem',
      lineHeight: '2rem',
      fontWeight: 'bold',
      margin: '0',
      marginBottom: '0.5rem',
    },
    teamDisplayInfoLocation: {
      fontSize: '1rem',
      lineHeight: '1rem',
      margin: '0',
      marginLeft: '0.5rem',
    },
    teamDisplayInfoLocationMobile: {
      fontSize: '0.8rem',
      lineHeight: '0.8rem',
      margin: '0',
      marginLeft: '0.5rem',
    },
    teamDisplayTabs: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      justifyContent: 'center',
      marginBottom: '20px',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[600],
    },
    teamDisplayTabsMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '20px',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[600],
      paddingLeft: '1rem'
    },
    teamDisplayTab: {
      color: colors.menubar[100],
      background: 'none',
      padding: '1rem',
      border: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    teamDisplayActiveTab: {
      color: colors.rose[500],
      background: 'none',
      padding: '1rem',
      border: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    teamDisplayTabMobile: {
      color: colors.menubar[100],
      background: 'none',
      padding: '1.25rem 0.5rem',
      border: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    teamDisplayActiveTabMobile: {
      color: colors.rose[500],
      background: 'none',
      padding: '1.25rem 0.5rem',
      border: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    teamDisplaySquad: {
      maxWidth: '1200px',
      margin: '0 auto',
      paddingBottom: '2rem',
      marginBottom: '2rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
    },
    teamDisplaySquadMobile: {
      maxWidth: '1200px',
      marginBottom: '2rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      paddingBottom : '1rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
    },
    teamDisplaySquadTable: {
    },
    teamDisplaySquadHeader: {
      fontWeight: 'bold',
      fontSize: '0.9rem',
      textTransform: 'uppercase',
    },
    teamDisplaySquadTableButton: {
      color: colors.menubar[200],
    },
    teamDisplayStatsMobile: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem 2em',
    },
    teamDisplayStats: {
      maxWidth: '1200px',
      margin: '0 auto',
      marginTop: '2rem',
      marginBottom: '2rem',
      padding: '0 2em',
    },
    teamDisplayStatsSelectContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem 0',
    },
    teamDisplayStatsSelect: {
      appearance: 'none',
      border: '1px solid',
      borderColor: colors.grey[600],
      borderRadius: '0.5rem',
      borderBottom: '3px solid',
      borderBottomColor: colors.rose[600],
      outline: 0,
      font: 'inherit',
      background: 'transparent',
      width: '18rem',
      height: '2.8rem',
      color: colors.grey[100],
      textTransform: 'uppercase',
      fontWeight: 'bold',
      cursor: 'pointer',
      textAlign: 'center',
      boxShadow: theme.shadows[5],
    },
    teamDisplayStatsSelectOption: {
      background: colors.grey[900],
      color: colors.grey[100],
      textTransform: 'uppercase',
    },
    teamDisplayStatsFootballSectionTitle: {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginTop: '2.5rem',
    },
    teamDisplayStatsFootballMainStatsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)', // Sin espacio entre "repeat" y "("
      gap: '0.9rem',
      marginTop: '1rem',
    },
    teamDisplayStatsFootballMainStatsGridMobile: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)', // Sin espacio entre "repeat" y "("
      gap: '1rem',
      marginTop: '1rem',
    },
    teamDisplayStatsFootballGridItem: {
      border: '1px solid',
      borderColor: colors.grey[600],
      borderRadius: '1rem',
      margin: '0.8rem 0.5rem',
    },
    teamDisplayStatsFootballGridItemName: {
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      textTransform: 'uppercase',
      height: '4rem',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[600],
    },
    teamDisplayStatsFootballGridItemValue: {
      fontSize: '3.5rem',
      fontWeight: 'bold',
      height: '6rem',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
    },
    teamDisplayStatsFootballMoreStatsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // Sin espacio entre "repeat" y "("
      gap: '0.7rem',
      marginTop: '1rem',
    },
    teamDisplayStatsFootballMoreStatsGridMobile: {
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 1fr)', // Sin espacio entre "repeat" y "("
      gap: '1rem',
      marginTop: '1rem',
    },
    teamDisplayStatsFootballMoreStatsTitle: {
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      textTransform: 'uppercase',
      padding: '0.7rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    teamDisplayStatsFootballMoreStatsTable: {
      borderTop: '2px solid',
      borderTopColor: colors.rose[500],
      textTransform: 'uppercase',
      fontSize: '1rem',
      '& ul': {
        listStyle: 'none',
        paddingInlineStart: '0.5rem',
        paddingInlineEnd: '0.5rem',
        '& li': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.5rem 1rem',
        },
      },
    },
    teamDisplayStatsFootballMoreStatsValue: {
      fontSize: '1.3rem',
      lineHeight: '1.2rem',
      fontWeight: 'bold',
      paddingLeft: '0.4rem',
    },
    teamDisplayStatsFootballGraphTitle: {
      fontSize: '1.1rem',
      lineHeight: '1.1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginTop: '1rem',
      textAlign: 'center',
    },
    teamDisplayStatsFootballGraphs: {
      paddingTop: '1rem',
    },
    teamDisplayCalendarMobile: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1.5rem 1em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    teamDisplayCalendar: {
      maxWidth: '1200px',
      margin: '0 auto',
      marginTop: '2rem',
      marginBottom: '2rem',
      padding: '0 2em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    playerDisplayDetails: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    playerDisplayDetailsRatings: {
      padding: '0.5rem 2rem',
    },
    playerDisplayDetailsRatingsMobile: {
      padding: '0 0.5rem',
    },
    playerDisplayDetailsRatingsContainerMobile: {

    },
    playerDisplayDetailsRatingsContainer: {
      display: 'flex',
    },
    playerDisplayDetailsRatingsGraphicMobile: {
      textAlign: 'center',
      height: '350px',
      padding: '2rem',
    },
    playerDisplayDetailsRatingsGraphic: {
      textAlign: 'center',
      width: '33%',
      height: '350px'
    },
    playerDisplayDetailsRatingsTitleMobile: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.4rem',
      padding: '1rem 0',
      fontWeight: 'bold',
    },
    playerDisplayDetailsRatingsTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.2rem',
      padding: '1rem 0',
      fontWeight: 'bold',
    },
    playerDisplayDetailsRatingsRating: {
      display: 'flex',
      alignItems: 'center',
    },
    playerDisplayDetailsRatingsRatingTitle: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      marginLeft: '0.5rem'
    },
    playerDisplayDetailsRatingsRatingValue: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      marginLeft: '1rem',
      padding: '0.3rem',
      background: colors.rose[800],
      width: '40px',
      height: '40px',
      borderRadius: '5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playerDisplayDetailsPersonal: {
      padding: '0.5rem 2rem',
    },
    playerDisplayDetailsPersonalMobile: {
      padding: '0 1rem',
      width: '100%',
    },
    playerDisplayDetailsPersonalTitle: {
      fontSize: '1.7rem',
      padding: '1rem 0',
      fontWeight: 'bold',
    },
    playerDisplayDetailsPersonalTitleMobile: {
      fontSize: '1.5rem',
      padding: '0.5rem 0',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    playerDisplayPersonalContainerMobile: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 auto',
      marginBottom: '2rem',
      justifyContent: 'center',
    },
    playerDisplayPersonalContainer: {
      display: 'flex',
      marginBottom: '2rem',
    },
    playerDisplayPersonalElementMobile: {
      padding: '0.5rem 0.8rem',
    },
    playerDisplayPersonalElement: {
      padding: '0.5rem 1.75rem',
    },
    playerDisplayPersonalElementTitleMobile: {
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
    },
    playerDisplayPersonalElementTitle: {
      padding: '0.5rem 0',
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      height: '100px',
      alignItems: 'middle',
      display: 'flex',
      flexDirection: 'column'
    },
    playerDisplayPersonalElementTitleIcon: {
      flex: '0 0 auto',
    },
    playerDisplayPersonalElementTitleName: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
    playerDisplayPersonalElementValueMobile: {
      padding: '0 1rem',
      fontSize: '0.9rem',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    playerDisplayPersonalElementValue: {
      padding: '0.2rem 0',
      fontSize: '1.1rem',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50%',
    },
    playerDisplaySocialsMobile: {
      paddingBottom: '2rem',
      marginTop: '1rem',
      fontSize: '1rem',
      '& span': {
        fontWeight: 'bold',
        fontSize: '1rem'
      },
    },
    playerDisplaySocials: {
      paddingBottom: '2rem',
      fontSize: '1.1rem',
      '& span': {
        fontWeight: 'bold',
        fontSize: '1.2rem'
      },
    },
    playerDisplaySocialsContainerMobile: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    playerDisplaySocialsContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 0'
    },
    playerDisplaySocialsElementMobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.grey[100],
      textDecoration: 'none',
      border: '1px solid',
      borderColor: colors.grey[200],
      padding: '0.5rem 1rem',
      borderRadius: '2rem',
      fontSize: '1.15rem',
      textAlign: 'center',
      margin: '0.5rem'
    },
    playerDisplaySocialsElement: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.grey[100],
      textDecoration: 'none',
      border: '1px solid',
      borderColor: colors.grey[200],
      padding: '0.5rem 1rem',
      borderRadius: '2rem',
      fontSize: '1.15rem',
      textAlign: 'center',
      margin: '0.5rem'
    },
    playerDisplaySocialsElementTitleMobile: {
      textAlign: 'center',
      justifyContent: 'center',
      marginRight: '0.3rem',
      lineHeight: '0.8rem'
    },
    playerDisplaySocialsElementTitle: {
      textAlign: 'center',
      justifyContent: 'center',
      marginRight: '0.3rem',
      lineHeight: '0.8rem'
    },
    playerDisplayHeaderFilter: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      borderBottom: '1px solid',
      borderBottomColor: colors.grey[600],
    },
    playerDisplayHeader: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      minHeight: '200px',
      marginTop: '65px',
      overflow: 'hidden',
      fontSize: '1.5rem',
      margin: '0 auto',
      maxWidth: '1200px',
      maxHeight: '600px',
    },
    playerDisplayHeaderMobile: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',  // Cambia a flex-direction: column
      minHeight: '300px',  // Ajusta la altura para centrar
      height: 'auto',  // Cambia la altura a auto
      marginTop: '65px',
      paddingTop: '2rem',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: '1.5rem',
      textTransform: 'uppercase',
    },
    playerDisplayHeaderContainer: {
      width: '80%',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    playerDisplayHeaderContainerMobile: {
      width: '100%%',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    playerDisplayImage: {
      height: '100%',
      width: '100%',
      minWidth: '350px',
      padding: '0 2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        maxHeight: '100%',
        maxWidth: '400px',
      },
    },
    playerDisplayImageMobile: {
      width: '100%',
      height: 'auto',  // Cambia la altura a auto
      padding: '0 5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',  // Centra la imagen horizontalmente
      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
      },
    },
    playerDisplayInfo: {
      position: 'absolute',
      bottom: '0',
      right: '3rem',
      padding: '0 5rem',
      textTransform: 'none',
      justifyContent: 'end',
      borderTopLeftRadius: '1.5rem',
      borderTopRightRadius: '1.5rem',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    playerDisplayInfoMobile: {
      position: 'absolute',
      bottom: '0',
      textTransform: 'none',
      justifyContent: 'end',
      borderTopLeftRadius: '1.5rem',
      borderTopRightRadius: '1.5rem',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      width: '80%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    playerDisplayDetailsFilter: {
      width: '100%',
      height: 'auto',  // Cambia la altura a auto
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    playerDisplayHeaderBackgroundName: {
      padding: '0',
      marginTop: '5vh',
      position: 'absolute',
      top: '0',
      fontSize: '30vw',
      lineHeight: '25vw',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      textAlign: 'center',
      background: 'linear-gradient(180deg, hsla(0, 0%, 93%, .2), hsla(0, 0%, 100%, 0) 90%)',
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      color: 'transparent',
      display: 'inline-block',
    },
    playerDisplayDetailsMobile: {
      width: '100%',
      height: 'auto', 
      alignItems: 'center',
      justifyContent: 'center',
    },
    playerDisplayMoreDetailsMobile: {
      padding: '0 2rem',
      fontSize: '1.25rem',
    },
    playerDisplayPositionMobile: {
      fontSize: '1rem',
      lineHeight: '0.5rem',
      paddingTop: '1.25rem',
      margin: '0',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: colors.rose[400],
    },
    playerDisplayNameMobile: {
      fontSize: '1.25rem',
      marginTop: '0',
      paddingTop: '0.5rem',
    },
    playerDisplayPosition: {
      fontSize: '1.25rem',
      lineHeight: '0.5rem',
      paddingTop: '2rem',
      margin: '0',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: colors.rose[400],
    },
    playerDisplayName: {
      fontSize: '2rem',
      marginTop: '0',
      paddingTop: '0.5rem',
    },
    playerDisplayHistoryContainerMobile: {
      maxWidth: '1200px',
      marginBottom: '2rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      paddingBottom : '1rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
    },
    playerDisplayHistoryContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      marginBottom: '2rem',
      paddingBottom: '1rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
    },
    playerDisplayHistoryTableHeaderCell: {
      textTransform: 'uppercase',
    },
    contactFormContainer: {
      marginTop: '65px',
      maxWidth: '800px',
      margin: 'auto',
      padding: '2em',
    },
    contactFormContainerMobile: {
      marginTop: '65px',
      maxWidth: '800px',
      margin: 'auto',
      padding: '2em',
    },
    contactFormTitle: {
      textTransform: 'uppercase',
      fontSize: '1.6rem',
    },
    contactFormLabel: {
      display: 'block',
      marginBottom: '0.1rem',
      textTransform: 'uppercase',
      fontSize: '1rem',
    },
    contactFormGroup: {
      border: '1px solid',
      borderColor: colors.menubar[400],
      borderRadius: '1rem',
      marginBottom: '1rem',
      padding: '2em',
      '& h4': {
        marginTop: '0.5rem',
        fontSize: '1.1rem',
      }
    },
    contactFormInput: {
      width: '100%',
      padding: '8px',
      marginBottom: '10px',
      border: '1px solid',
      borderRadius: '4px',
    },
    contactFormTextArea: {
      width: '100%',
      height: '4rem',
      padding: '8px',
      marginBottom: '10px',
      border: '1px solid',
      borderRadius: '4px',
    },
    contactFormButton: {
      padding: '0.6em 3.4em',
      border: '1px solid',
      borderColor: colors.menubar[400],
      borderRadius: '1rem',
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1rem',
      background: colors.menubar[500],
      color: colors.menubar[200],
    },
    contactFormError: {
      color: colors.redAccent[500],
      padding: '0.6em',
      fontSize: '1rem',
    },
    contactFormSuccess: {
      color: colors.greenAccent[500],
      padding: '0.6em',
      fontSize: '1rem',
    },
    generalRatings: {
      width: '100%',
      height: '100%',
      padding: '0.5rem'
    },
    generalRatingsMobile: {
      width: '100%',
      height: '100%',
      padding: '0.5rem'
    },
    sponsors: {
      display: 'flex',
      padding: '1rem 1rem',
      marginBottom: '2rem'
    },
    sponsorItem: {
      height: '300px',
      padding: '0.5rem',
      textAlign: 'center',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      textDecoration: 'none',
      color: colors.grey[100]
    },
    sponsorItemImage: {
      height: '80%'
    },
    sponsorItemName: {
      height: '20%',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    teamDisplayDetailsMobile: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 2em',
      marginBottom: '2rem',
    },
    teamDisplayDetails: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 2em',
      marginBottom: '2rem',
    },
    teamDisplayDetailsTitleMobile: {
      textTransform: 'uppercase',
      fontSize: '1.3rem',
      fontWeight: 'bold',
      marginTop: '2rem',
    }, 
    teamDisplayDetailsTitle: {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginTop: '2rem',
    },
    teamDisplayDetailsKitsMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '1rem',
    }, 
    teamDisplayDetailsKits: {
      display: 'flex',
    },
    teamDisplayDetailsKit: {
      padding: '0.5rem',
      margin: '0.3rem',
      textAlign: 'center',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      textDecoration: 'none',
      color: colors.grey[100],
      borderBottom: '2px solid',
      borderBottomColor: colors.rose[400]
    },
    teamDisplayDetailsKitImageMobile: {
      width: '130px',
      height: '130px',
    },
    teamDisplayDetailsKitImage: {
      width: '180px',
      height: '180px',
    },
    teamDisplayDetailsKitImageSource: {
      width: '100%',
      height: '100%',
    },
    teamDisplayDetailsKitName: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1.1rem'
    },
    teamDisplayDetailsLastMatchesMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '1rem',
    },
    teamDisplayDetailsLastMatches: {
      display: 'flex',
    },
    teamDisplayDetailsLastMatchesEntry: {
      padding: '0.5rem',
      margin: '0.3rem',
      textAlign: 'center',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      textDecoration: 'none',
      color: colors.grey[100],
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '200px',
    },
    teamDisplayDetailsLastMatchesEntryImage: {
      width: '80px',
      height: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      marginBottom: '0.5rem'
    },
    teamDisplayDetailsLastMatchesEntryImageSource: {
      width: '100%',
      height: '100%',
    },
    teamDisplayDetailsLastMatchesEntryResult: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '0.5rem'
    },
    teamDisplayDetailsLastMatchesEntryResultVictory: {
      background: colors.results[200],
      borderRadius: '2rem',
      fontWeight: 'bold',
      color: colors.grey[800],
      fontSize: '1rem',
      padding: '0.25rem 0.75rem',
    },
    teamDisplayDetailsLastMatchesEntryResultLoss: {
      background: colors.results[100],
      borderRadius: '2rem',
      fontWeight: 'bold',
      color: colors.grey[800],
      fontSize: '1rem',
      padding: '0.25rem 0.75rem',
    },
    teamDisplayDetailsLastMatchesEntryResultDraw: {
      background: colors.results[300],
      borderRadius: '2rem',
      fontWeight: 'bold',
      color: colors.grey[800],
      fontSize: '1rem',
      padding: '0.25rem 0.75rem',
    },
    teamDisplayDetailsLastMatchesEntryScore: {
      textTransform: 'uppercase',
      padding: '0.1rem',
      fontSize: '1.1rem',
      fontWeight: 'bold',
    },
    teamDisplayDetailsLastMatchesEntryVsTeam: {
      textTransform: 'uppercase',
      padding: '0.1rem',
      fontSize: '1.1rem',
      fontWeight: 'bold',
    },
    teamDisplayDetailsLastMatchesEntryDate: {
      textTransform: 'uppercase',
      fontSize: '1rem',
      paddingBottom: '0.5rem'
    },
    teamDisplayDetailsStaffMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '1rem',
    },
    teamDisplayDetailsStaff: {
      display: 'flex',
    },
    teamDisplayDetailsStaffEntry: {
      padding: '0.5rem',
      margin: '0.3rem',
      textAlign: 'center',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[800],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      textDecoration: 'none',
      color: colors.grey[100],
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '200px',
    },
    teamDisplayDetailsStaffEntryImage: {
      height: '160px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      marginBottom: '0.5rem'
    },
    teamDisplayDetailsStaffEntryName: {
      textTransform: 'uppercase',
      padding: '0.1rem',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    teamDisplayDetailsStaffEntryRole: {
      fontSize: '1rem',
      lineHeight:  '0.8rem',
      paddingBottom: '1rem'
    },
    aboutUsContainer: {
      marginTop: '65px',
      marginBottom: '2rem',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 2em',
      paddingTop: '1rem'
    },
    aboutUsContainerMobile: {
      marginTop: '65px',
      marginBottom: '2rem',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 2em',
    },
    aboutUsSection: {
      paddingLeft: '1rem',
      '& p': {
        paddingLeft: '1rem'
      }
    },
    aboutUsTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '2rem',
      marginTop: '3rem',
      marginBottom: '1rem',
      color: colors.rose[300]
    },
    aboutUsSubtitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1.3rem',
      color: colors.rose[300]
    },
    aboutUsValues: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: colors.rose[300]
    },
    aboutUsAfterValues: {
      marginTop: '0.5rem',
    },
    aboutUsTeamStaffContainer: {
      display: 'flex'
    },
    aboutUsTeamStaffContainerMobile: {
    },
    aboutUsTeamStaffMember: {
      padding: '0.5rem',
      margin: '0.3rem',
      textAlign: 'center',
      textDecoration: 'none',
      color: colors.grey[100],
      alignItems: 'center',
      justifyContent: 'center',
    },
    aboutUsTeamStaffMemberImage: {
      height: '250px',
      display: 'flex',
      '& img': {
        height: '100%',
        alignSelf: 'flex-end',
        bottom: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      }
    },
    aboutUsTeamStaffMemberDetails: {
      padding: '1rem 4rem',
      borderTop: '1px solid',
      borderBottom: '4px solid',
      borderLeft: '2px solid',
      borderRight: '2px solid',
      borderRadius: '12px',
      borderTopColor: colors.grey[800],
      borderRightColor: colors.grey[800],
      borderLeftColor: colors.grey[800],
      borderBottomColor: colors.grey[700],
      boxShadow: theme.shadows[15],
      zIndex: 2,
    },
    aboutUsTeamStaffMemberDetailsName: {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    aboutUsTeamStaffMemberDetailsRole: {
    },
    playerDisplayAwardsContainerMobile: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem 2rem',
    },
    playerDisplayAwardsContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem 2rem',
    },
    playerDisplayAwardsSectionMobile: {
      marginBottom: '2rem',
      '& h3': {
        textTransform: 'uppercase',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginTop: '0',
      }
    },
    playerDisplayAwardsSection: {
      marginBottom: '2rem',
      '& h3': {
        textTransform: 'uppercase',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        marginTop: '0',
      }
    },
    playerDisplayAwardsSectionContainerMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '1rem',
    },
    playerDisplayAwardsSectionContainer: {
      display: 'flex',
    },
    playerDisplayAwardsEntryMobile: {
      margin: '0 0.5rem'
    },
    playerDisplayAwardsEntry: {
      margin: '0 0.5rem'
    },
    playerDisplayAwardsEntryImageMobile: {
      height: '130px',
      '& img': {
        height: '100%',
      }
    },
    playerDisplayAwardsEntryImage: {
      height: '150px',
      '& img': {
        height: '100%',
      }
    },
    playerDisplayAwardsEntryDetailsMobile: {
      textTransform: 'uppercase',
      textAlign: 'center'
    },
    playerDisplayAwardsEntryDetails: {
      textTransform: 'uppercase',
      textAlign: 'center',
      '& p': {
        marginTop: '0',
        marginBottom: '0'
      }
    },
    playerDisplayAwardsDetailsTitle: {
      fontWeight: 'bold',
      fontSize: '1.1rem'
    },
    playerDisplayAwardsEmpty: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.2rem',
      paddingBottom: '1rem'
    },
    votingsContainer: {
      marginTop: '65px',
      maxWidth: '800px',
      margin: 'auto',
      padding: '1rem',
      '& h2': {
        fontSize: '1.8rem',
        textTransform: 'uppercase',
        marginBottom: '0.5rem',
      },
      '& p': {
        fontSize: '1.1rem',
        margin: '0.5rem 0',
      }
    },
    votingsContainerMobile: {
      marginTop: '65px',
      maxWidth: '800px',
      margin: 'auto',
      padding: '1rem',
      '& h2': {
        fontSize: '1.8rem',
        textTransform: 'uppercase',
        marginBottom: '0.5rem',
      },
      '& p': {
        fontSize: '1.1rem',
        margin: '0.5rem 0',
      }
    },
    votingsCurrentContainerMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '2rem',
    },
    votingsCurrentContainer: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '2rem',
    },
    votingsLastContainerMobile: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '2rem',
    },
    votingsLastContainer: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      marginBottom: '2rem',
    },
    votingsEntryMobile: {
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      backgroundImage: 'url("https://madflowersbucket.s3.eu-west-3.amazonaws.com/backgrounds/playerVotingBackground.png")', // Reemplaza "ruta/de/la/imagen.jpg" con la URL de tu imagen
      backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
      backgroundPosition: 'center',
      textDecoration: 'none',
      color: colors.menubar[200],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0.3rem',
      minWidth: '200px',
    },
    votingsEntry: {
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      backgroundImage: 'url("https://madflowersbucket.s3.eu-west-3.amazonaws.com/backgrounds/playerVotingBackground.png")', // Reemplaza "ruta/de/la/imagen.jpg" con la URL de tu imagen
      backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
      backgroundPosition: 'center',
      textDecoration: 'none',
      color: colors.menubar[200],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0.3rem',
      minWidth: '200px',
    },
    votingsEntryMatchPreviewMobile: {
      height: '100px',
      padding: '0.5rem',
      '& img': {
        height: '100%',
        borderRadius: '50rem',
        margin: '0 0.5rem',
      }
    },
    votingsEntryMatchPreview: {
      height: '100px',
      padding: '0.5rem',
      '& img': {
        height: '100%',
        borderRadius: '50rem',
        margin: '0 0.5rem',
      }
    },
    votingsEntryDetails: {
      textAlign: 'center',
      paddingTop: '1rem',
      background: theme.palette.background.paper,
      width: '100%',
      height: '100%',
      padding: '1rem',
      borderTop: '1px solid',
      borderColor: colors.grey[600],
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      '& h3': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        margin: '0.5rem 0'
      },
    },
    votingsEntryDetailsType: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '1rem',
      margin: '0.4rem 0',
      color: colors.rose[500]
    },
    votingsEntryDetailsLittle: {
      fontWeight: 'normal',
      fontSize: '1rem',
      margin: '0.2rem 0'
    },
    votingDisplayContainer: {
      marginTop: '65px',
      maxWidth: '800px',
      margin: 'auto',
      padding: '1rem',
      marginBottom: '1rem',
    },
    votingDisplayContainerMobile: {
      marginTop: '65px',
      maxWidth: '800px',
      margin: 'auto',
      padding: '1rem',
      marginBottom: '1rem',
    },
    votingDisplayTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '2.2rem',
      marginBottom: '0.2rem',
    },
    votingDisplayTitleMobile: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '2rem',
      marginBottom: '0.4rem',
    },
    votingDisplayMonth: {
      textTransform: 'uppercase',
      fontSize: '1.7rem',
      fontWeight: 'bold',
      margin: '0',
      color: colors.rose[500]
    },
    votingDisplayMatchDetails: {
      padding: '1rem 2rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      textAlign: 'center',
      '& h3': {
        textTransform: 'uppercase',
        margin: '0.2rem 0',
        fontSize: '1.5rem',
      }
    },
    votingDisplayMatchDetailsMobile: {
      padding: '1rem 2rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      textAlign: 'center',
      '& h3': {
        textTransform: 'uppercase',
        margin: '0.2rem 0',
        fontSize: '1.5rem',
      }
    },
    votingDisplayMatchDetailsVersus: {
      height: '90px',
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& img': {
        height: '90%',
        borderRadius: '50rem',
        
      },
      '& p': {
        fontSize: '3.5rem',
        fontWeight: 'bold',
        '& span': {
          fontSize: '2.5rem',
          color: colors.rose[400]
        }
      }
    },
    votingDisplayMatchDetailsVersusMobile: {
      height: '90px',
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& img': {
        height: '90%',
        borderRadius: '50rem',
        
      },
      '& p': {
        fontSize: '2.2rem',
        fontWeight: 'bold',
        '& span': {
          fontSize: '2rem',
          color: colors.rose[400]
        }
      }
    },
    votingDisplayMatchMoreDetails: {
      '& p': {
        margin: '0.2rem 0',
        fontSize: '1rem'
      }
    },
    votingDisplayNomineesContainer: {
      padding: '1rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      marginTop: '1rem',
    },
    votingDisplayNomineesContainerMobile: {
      padding: '1rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      marginTop: '1rem',
    },
    votingDisplayNomineesHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      '& h3': {
        margin: '0',
        textAlign: 'center',
        marginLeft: '1rem',
        fontSize: '1.5rem',
      }
    },
    votingDisplayNomineesHeaderMobile: {
      '& h3': {
        margin: '0',
        marginBottom: '0.5rem',
        marginLeft: '1rem',
        fontSize: '1.5rem',
      }
    },
    votingDisplayNomineesPoints: {
      fontSize: '1.5rem',
      marginRight: '1rem',
      '& span': {
        padding: '0.5rem 0.6rem',
        borderRadius: '50rem',
        background: colors.rose[600],
        color: colors.grey[900],
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.6rem'
      }
    },
    votingDisplayNomineesPointsMobile: {
      fontSize: '1.2rem',
      marginLeft: '1rem',
      '& span': {
        padding: '0.4rem 0.5rem',
        borderRadius: '50rem',
        background: colors.rose[600],
        color: colors.grey[900],
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.4rem'
      }
    },
    votingDisplayNomineesEntry: {
      display: 'flex',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      borderTop: '4px solid',
      borderTopColor: colors.rose[600],
      boxShadow: theme.shadows[4],
      backgroundImage: 'url("https://madflowersbucket.s3.eu-west-3.amazonaws.com/backgrounds/playerVotingBackground.png")', // Reemplaza "ruta/de/la/imagen.jpg" con la URL de tu imagen
      backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
      backgroundPosition: 'center', // Centra la imagen en el contenedor
      margin: '0.8rem',
      padding: '1rem'
    },
    votingDisplayNomineesEntryMobile: {
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      borderTop: '4px solid',
      borderTopColor: colors.rose[600],
      boxShadow: theme.shadows[4],
      backgroundImage: 'url("https://madflowersbucket.s3.eu-west-3.amazonaws.com/backgrounds/playerVotingBackground.png")', // Reemplaza "ruta/de/la/imagen.jpg" con la URL de tu imagen
      backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
      backgroundPosition: 'center', // Centra la imagen en el contenedor
      margin: '0.8rem',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    votingDisplayNomineesEntryImage: {
      height: '120px',
      '& img': {
        height: '100%',
        borderRadius: '50rem',
        border: '2px solid'
      }
    },
    votingDisplayNomineesEntryImageMobile: {
      height: '120px',
      '& img': {
        height: '100%',
        borderRadius: '50rem',
        border: '2px solid'
      }
    },
    votingDisplayNomineesEntryDetails: {
      width: '100%',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      '& h3': {
        margin: '0',
        fontSize: '2.1rem',
        lineHeight: '1.7rem'
      },
      '& h4': {
        margin: '0',
        textTransform: 'uppercase',
        fontSize: '1.3rem',
        color: colors.rose[600],
      }
    },
    votingDisplayNomineesEntryDetailsMobile: {
      width: '100%',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      '& h3': {
        margin: '0',
        fontSize: '2.1rem',
        lineHeight: '1.7rem'
      },
      '& h4': {
        margin: '0',
        textTransform: 'uppercase',
        fontSize: '1.3rem',
        color: colors.rose[600],
      }
    },
    votingDisplayNomineesEntryDetailsStatsContainerMobile: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '0.6rem',
      paddingBottom: '0.6rem',
      justifyContent: 'center'
    },
    votingDisplayNomineesEntryDetailsStatsContainer: {
      display: 'flex',
      paddingTop: '0.6rem',
      paddingBottom: '0.6rem',
    },
    votingDisplayNomineesEntryDetailsStatEntryMobile: {
      padding: '0.2rem 0.5rem',
      display: 'flex',
    },
    votingDisplayNomineesEntryDetailsStatEntry: {
      padding: '0.2rem 0.5rem',
      display: 'flex',
    },
    votingDisplayNomineesEntryDetailsStatEntryIconMobile: {
      fontSize: '1.8rem',
      lineHeight: '1rem',
    },
    votingDisplayNomineesEntryDetailsStatEntryIcon: {
      fontSize: '1.8rem',
      lineHeight: '1rem',
    },
    votingDisplayNomineesEntryDetailsStatEntryIconBasketballMobile: {
      fontSize: '1.5rem',
      lineHeight: '1.6rem',
    },
    votingDisplayNomineesEntryDetailsStatEntryIconBasketball: {
      fontSize: '1.8rem',
      lineHeight: '1.8rem',
    },
    votingDisplayNomineesEntryDetailsStatEntryValueMobile: {
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      marginLeft: '0.5rem',
      fontWeight: 'bold',
    },
    votingDisplayNomineesEntryDetailsStatEntryValue: {
      fontSize: '1.9rem',
      lineHeight: '1.8rem',
      marginLeft: '0.3rem',
      fontWeight: 'bold',
    },
    votingDisplayNomineesEntryScoreContainer: {
      width: '100%',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        textTransform: 'uppercase',
        fontSize: '1.2rem',
        fontWeight: 'bold'
      }
    },
    votingDisplayNomineesEntryScoreContainerMobile: {
      width: '100%',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: 'bold'
      }
    },
    votingDisplayNomineesScore: {
      border: '4px solid',
      borderRadius: '1.6rem',
      borderColor: colors.grey[100],
      boxShadow: theme.shadows[4],
      background: 'transparent',
      color: colors.grey[200],
      fontSize: '1rem',
      fontWeight: 'bold',
      padding: '0.5rem 0.5rem',
      width: '5rem',
      height: '5rem',
      textAlign: 'center',
      fontSize: '1.8rem',
    },
    votingButton: {
      marginLeft: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      padding: '0.8rem 5rem',
      textTransform: 'uppercase',
      fontSize: '1.8rem',
      fontWeight: 'bold',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      color: colors.grey[100],
      cursor: 'pointer', /* Añade esta línea */
    },
    voteResult: {
      margin: '0 auto',
      marginTop: '1rem',
      marginBottom: '1rem',
      width: '100%',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    votingDisplayResult: {
      padding: '1rem 2rem',
      border: '1px solid',
      borderRadius: '10px',
      borderColor: colors.grey[600],
      boxShadow: theme.shadows[4],
      backgroundImage: 'url("https://madflowersbucket.s3.eu-west-3.amazonaws.com/backgrounds/playerVotingBackground.png")', // Reemplaza "ruta/de/la/imagen.jpg" con la URL de tu imagen
      backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
      backgroundPosition: 'center', // Centra la imagen en el contenedor
      textAlign: 'center',
      marginTop: '1rem', 
      '& h3': {
        textTransform: 'uppercase',
        margin: '0.2rem 0',
        fontSize: '1.4rem',
      }
    },
    votingDisplayResultPlayerDisplayImage: {
      height: '170px',
      marginTop: '1rem',
      marginBottom: '1rem',
      '& img': {
        height: '100%',
        borderRadius: '50rem',
        border: '2px solid'
      }
    },
    votingDisplayResultPlayerDisplayImageMobile: {
      height: '170px',
      marginTop: '1rem',
      marginBottom: '1rem',
      '& img': {
        height: '100%',
        borderRadius: '50rem',
        border: '2px solid'
      }
    },
    votingDisplayResultPlayerDisplayDetails: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
      '& p': {
        margin: '0',
        '& span': {
          color: colors.rose[600],
        }
      },
      '& h4': {
        fontSize: '1.8rem',
        margin: '0',
      }
    },
    votingDisplayResultPlayerDisplayDetailsMobile: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
      '& p': {
        margin: '0',
        '& span': {
          color: colors.rose[600],
        }
      },
      '& h4': {
        fontSize: '1.8rem',
        margin: '0',
      }
    },
  };
};

const useStyles = makeStyles(styles);
export default useStyles;