import React from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import imageMap from '../../maps/imageMap.js';
import iconMap from '../../maps/iconMap.js';
import backgroundImageMap from '../../maps/backgroundImageMap.js';
import PlaceIcon from '@mui/icons-material/Place';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const TeamDisplayHeader = ({ team }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const imagePath = imageMap[team.badgeLocator];
    const icon = iconMap[team.sport];

    const getBackgroundURL = (value) => {
      return backgroundImageMap[value] || backgroundImageMap["default"];
    };

    const headerBackground = getBackgroundURL(team.sport);

    const backgroundHeaderStyle = {
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    return (
      <div style={backgroundHeaderStyle}>
        <div className={classes.teamDisplayHeaderFilter}>
          <Box className={isMobile ? classes.teamDisplayHeaderMobile : classes.teamDisplayHeader}>
            <div className={isMobile ? classes.teamDisplayBadgeMobile : classes.teamDisplayBadge}>
                <img
                    src={imagePath}
                    alt="Escudo del equipo"
                    className={classes.teamDisplayBadgeImage}
                />
            </div>
            <div className={isMobile ? classes.teamDisplayInfoMobile : classes.teamDisplayInfo}>
                <p className={isMobile ? classes.teamDisplayInfoTitleMobile : classes.teamDisplayInfoTitle}>{team.name}</p>
                <p className={isMobile ? classes.teamDisplayInfoLocationMobile : classes.teamDisplayInfoLocation}><PlaceIcon /> {team.location}</p>
                <p className={isMobile ? classes.teamDisplayInfoLocationMobile : classes.teamDisplayInfoLocation}><EmojiEventsIcon /> {team.competition}</p>
                <p className={isMobile ? classes.teamDisplayInfoLocationMobile : classes.teamDisplayInfoLocation}><CalendarMonthIcon /> Desde {team.foundationYear}</p>
            </div>
            
          </Box>
        </div>
      </div>
    );
};

export default TeamDisplayHeader;