import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import awardNameMap from "../../maps/awardNameMap";
import VotingNomineeFootball from "../../components/votings/VotingNomineeFootball";
import VotingNomineeBasketball from "../../components/votings/VotingNomineeBasketball";
import VotingNonGameNomineeFootball from "../../components/votings/VotingNonGameNomineeFootball";
import VotingNonGameNomineeBasketball from "../../components/votings/VotingNonGameNomineeBasketball";
import VotingDisplayMatchDetails from "../../components/votings/VotingDisplayMatchDetails";
import VotingDisplayResult from "../../components/votings/VotingDisplayResult";
import CookiesNecessaryNonAccepted from "../../components/cookies/CookiesNecessaryNonAccepted";

const VotingDisplay = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const { votingId } = useParams();
  const [voting, setVoting] = useState(null); // Inicializa como null o un objeto vacío, no una matriz
  const [nominees, setNominees] = useState([]);
  const [matchStats, setMatchStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMatchLoaded, setIsMatchLoaded] = useState(false);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);

  const [totalPoints, setTotalPoints] = useState(10);
  const [availablePoints, setAvailablePoints] = useState(totalPoints);
  const [inputValues, setInputValues] = useState({});
  const [isValidVote, setIsValidVote] = useState({});
  const [winner, setWinner] = useState({});

  // Función para obtener el nombre del mes a partir de su número
  const getMonthName = (monthNumber) => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return monthNames[monthNumber - 1];
  };

  // Función para obtener el mes y año a partir de la fecha en formato "dd/mm/yyyy hh:mm:ss"
  const getMonthAndYear = (dateString) => {
    const parts = dateString.split(" "); // Divide la cadena en partes utilizando el espacio como separador
    const datePart = parts[0]; // Obtiene la parte de la fecha
    const [day, month, year] = datePart.split("/"); // Divide la fecha en día, mes y año utilizando "/" como separador
    return `${getMonthName(parseInt(month))} ${year}`;
  };
  const getYear = (dateString) => {
    const parts = dateString.split(" "); // Divide la cadena en partes utilizando el espacio como separador
    const datePart = parts[0]; // Obtiene la parte de la fecha
    const [day, month, year] = datePart.split("/"); // Divide la fecha en día, mes y año utilizando "/" como separador
    return `${year}`;
  };

  useEffect(() => {
    setHasAcceptedCookies(sessionStorage.getItem("cookiesAccepted"));

    // Realizar una solicitud GET al backend de Spring
    setIsMatchLoaded(false);
    let isLoading = true; // Agregamos esta variable para controlar el estado de carga

    axios
      .get("/voting/" + votingId)
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        setVoting(response.data);
        if (response.data.match) {
          axios
            .get(
              "/stats/" +
                response.data.match.localTeam.sport +
                "/match/" +
                response.data.match.idMatch
            )
            .then((statsResponse) => {
              // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
              setMatchStats(statsResponse.data);
              setIsMatchLoaded(true);
            })
            .catch((error) => {
              // Manejo de errores en caso de que la solicitud falle
              console.error(
                "Error al obtener los datos de los nominados:",
                error
              );
              setIsMatchLoaded(false);
            });
        }
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error("Error al obtener los datos de la nominacion:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    axios
      .get("/voting/" + votingId + "/nominees")
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        setNominees(response.data);
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error("Error al obtener los datos de los nominados:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .get("/voting/" + votingId + "/winner")
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        console.log("El ganador ha sido determinado.");
        setWinner(response.data);
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error("Error al obtener los datos del ganador:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10); // Convierte el valor a entero

    // Si el valor no es un número válido, se considera como 0
    const inputValue = isNaN(parsedValue) ? 0 : parsedValue;

    const totalInputs = document.querySelectorAll(
      `.${classes.votingDisplayNomineesScore}`
    );
    let totalSum = 0;

    // Recorre todos los inputs y suma sus valores
    for (const input of totalInputs) {
      const inputValue = parseInt(input.value, 10);
      if (!isNaN(inputValue)) {
        totalSum += inputValue;
      }
    }

    // Actualiza la variable de estado availablePoints
    const remainingPoints = Math.max(0, totalPoints - totalSum); // Asegura que availablePoints no sea negativo
    setAvailablePoints(remainingPoints);

    // Indica si se ha superado el límite de puntos
    const isOverLimit = totalSum > totalPoints;

    if (isOverLimit) {
      // Puedes mostrar un mensaje de error o alerta aquí
      setIsValidVote(false);
    } else {
      setIsValidVote(true);
    }
  };

  const handleVoteClick = async () => {
    const hasVoted = localStorage.getItem(`hasVoted_${votingId}`);

    if (hasVoted) {
      console.log(`Ya has votado en la votación con ID ${votingId}.`);
      var divVoteResult = document.getElementById("voteResult");
      if (divVoteResult) {
        // Agregar texto al contenido del div
        divVoteResult.textContent =
          "Ya has participado en esta votación con anterioridad.";
        divVoteResult.style.color = "#ffcd70";
      } else {
        console.error('El div con id "voteResult" no existe.');
      }
      return;
    }
    const votes = {}; // Objeto para almacenar los votos

    const totalInputs = document.querySelectorAll(
      `.${classes.votingDisplayNomineesScore}`
    );
    for (const input of totalInputs) {
      const inputId = input.id; // Obtiene el ID del input
      const inputValue = parseInt(input.value, 10); // Obtiene el valor del input

      if (!isNaN(inputValue)) {
        // Extract numeric part of input ID using regex
        const playerId = inputId.match(/player(\d+)/)[1]; // Assuming IDs follow "player###" format
        votes[playerId] = inputValue; // Store vote with extracted player ID
      }
    }

    const votesObject = {
      // Create Votes object for API call
      voting: {
        idVoting: votingId,
      },
    };

    const votesToPlayers = []; // Array to store votesToPlayers objects
    for (const key in votes) {
      votesToPlayers.push({
        player: { idPlayer: parseInt(key, 10) }, // Assuming player ID is stored in the input ID
        points: votes[key],
      });
    }

    // Código para procesar los votos recopilados (enviar a servidor, etc.)
    // console.log('Votos recopilados:', votes);
    // console.log('Voto en objeto:', votesObject);
    // console.log('Votos a jugadores:', votesToPlayers);

    try {
      const response = await axios.post("/voting/vote", {
        idVoting: votingId,
        votesToPlayers,
      });

      if (response.status === 201) {
        console.log("Votos enviados con éxito:", response.data);
        //Se guarda la informacion de que se ha votado
        localStorage.setItem(`hasVoted_${votingId}`, "true");
        // Handle successful vote submission (e.g., reset form, show confirmation)
        var divVoteResult = document.getElementById("voteResult");
        if (divVoteResult) {
          // Agregar texto al contenido del div
          divVoteResult.textContent = "El voto ha sido enviado correctamente.";
          divVoteResult.style.color = "#a6ff94";
        } else {
          console.error('El div con id "voteResult" no existe.');
        }
      } else {
        console.error("Error al enviar votos:", response.statusText);
        var divVoteResult = document.getElementById("voteResult");
        if (divVoteResult) {
          // Agregar texto al contenido del div
          divVoteResult.textContent +=
            "Ha ocurrido un error al enviar el voto.";
          divVoteResult.style.color = "#ff524f";
        } else {
          console.error('El div con id "voteResult" no existe.');
        }
      }
    } catch (error) {
      console.error("Error de red:", error);
    }
  };

  if (hasAcceptedCookies) {
    return (
      <div>
        <div
          className={
            isMobile
              ? classes.votingDisplayContainerMobile
              : classes.votingDisplayContainer
          }
        >
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <h2 className={isMobile ? classes.votingDisplayTitleMobile : classes.votingDisplayTitle}>
                {Object.keys(voting).length > 0 && (awardNameMap[voting.details] || awardNameMap["default"])}
              </h2>
              {voting &&
                Object.keys(voting).length > 0 &&
                voting.details == "POTM" && (
                  <p className={classes.votingDisplayMonth}>{getMonthAndYear(voting.match.date)}</p>
                )}
                {voting &&
                  Object.keys(voting).length > 0 &&
                  voting.details == "POTY" && (
                    <p className={classes.votingDisplayMonth}>Temporada {voting.match.season}</p>
                  )}
              {voting &&
                Object.keys(voting).length > 0 &&
                voting.details == "POTG" &&
                voting.match && <VotingDisplayMatchDetails voting={voting} />}
              {voting &&
                new Date(voting.dateEnding) < Date.now() &&
                winner &&
                Object.keys(winner).length > 0 && (
                  <VotingDisplayResult voting={voting} winner={winner} />
                )}
              {voting &&
                Object.keys(voting).length > 0 &&
                new Date(voting.dateEnding) > Date.now() && (
                  <div
                    className={
                      isMobile
                        ? classes.votingDisplayNomineesContainerMobile
                        : classes.votingDisplayNomineesContainer
                    }
                  >
                    <div
                      className={
                        isMobile
                          ? classes.votingDisplayNomineesHeaderMobile
                          : classes.votingDisplayNomineesHeader
                      }
                    >
                      <h3>NOMINADOS</h3>
                      <div
                        className={
                          isMobile
                            ? classes.votingDisplayNomineesPointsMobile
                            : classes.votingDisplayNomineesPoints
                        }
                      >
                        PUNTOS DISPONIBLES: <span>{availablePoints}</span> /{" "}
                        <span>{totalPoints}</span>
                      </div>
                    </div>
                    {nominees.map((nominee, index) => {
                      // Buscar la entrada en matchStats cuyo player.idPlayer coincida con nominee.player.idPlayer
                      if (
                        voting &&
                        Object.keys(voting).length > 0 &&
                        voting.details == "POTG"
                      ) {
                        const matchStat = matchStats.find(
                          (stat) =>
                            stat.player.idPlayer === nominee.player.idPlayer
                        );

                        let sport = null;
                        if (matchStat) sport = matchStat.player.team.sport;

                        if (sport === "football") {
                          return (
                            <VotingNomineeFootball
                              nominee={nominee}
                              matchStat={matchStat}
                              handleInputChange={handleInputChange}
                            />
                          );
                        } else if (sport === "basketball") {
                          return (
                            <VotingNomineeBasketball
                              nominee={nominee}
                              matchStat={matchStat}
                              handleInputChange={handleInputChange}
                            />
                          );
                        } else {
                          return <div></div>;
                        }
                      } else {
                        let sport = null;
                        if (nominee) sport = nominee.player.team.sport;
                        if (sport === "football") {
                          return (
                            <VotingNonGameNomineeFootball
                              nominee={nominee}
                              voting={voting}
                              season={voting.match.season}
                              handleInputChange={handleInputChange}
                            />
                          );
                        } else if (sport === "basketball") {
                          return (
                            <VotingNonGameNomineeBasketball
                              nominee={nominee}
                              voting={voting}
                              season={voting.match.season}
                              handleInputChange={handleInputChange}
                            />
                          );
                        } else {
                          return <div></div>;
                        }
                      }
                    })}
                    <button
                      className={classes.votingButton}
                      disabled={!isValidVote} // Deshabilita el botón si isValidVote es falso
                      onClick={handleVoteClick} // Función que se ejecuta al hacer clic
                    >
                      Votar
                    </button>
                    <div className={classes.voteResult} id={"voteResult"}></div>
                  </div>
                )}
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  } else {
    return <CookiesNecessaryNonAccepted />;
  }
};

export default VotingDisplay;
