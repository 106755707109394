import React, { useState, useEffect } from 'react';
import Hero from "../../components/home/Hero";
import Footer from "../../components/Footer";
import AboutUs from "../../components/home/AboutUs";
import NextMatches from "../../components/home/NextMatches";
import ImageCarousel from "../../components/home/ImageCarousel";
import News from "../../components/home/News";
import Sponsors from "../../components/home/Sponsors";
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';

function getRandomMomentImageUrls(numberOfUrls, minValue, maxValue) {
    // Validar entrada
    if (numberOfUrls <= 0) {
      console.error("Número de URLs debe ser mayor a 0");
      return;
    }
    if (minValue > maxValue) {
      console.error("Valor mínimo no puede ser mayor que el valor máximo");
      return;
    }
  
    // Generar array de URLs vacías
    const randomUrls = Array(numberOfUrls).fill("");
  
    // Generar números aleatorios únicos
    const usedNumbers = new Set();
    for (let i = 0; i < numberOfUrls; i++) {
      let randomNumber;
      do {
        randomNumber = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
      } while (usedNumbers.has(randomNumber));
      usedNumbers.add(randomNumber);
  
      // Generar URL con el número aleatorio
      randomUrls[i] = getMomentsImageUrl(randomNumber);
    }
  
    // Retornar array de URLs
    return randomUrls;
}

const getMomentsImageUrl = ( momentsNumber ) => {
    return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/moments/moments' + momentsNumber + '.JPG' ;
}

const Home = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    return (
        <div className={classes.homeContainer}>
            <Hero />
            <AboutUs />
            <NextMatches />
            {/* <News /> */}
            <ImageCarousel imagenes={getRandomMomentImageUrls(8, 1, 32)}/>
            <Sponsors />
            <Footer />
        </div>
    );
};

export default Home;