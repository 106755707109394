// imageMap.js
const getTeamImageUrl = ( teamLogoImageName ) => {
  return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/team/' + teamLogoImageName;
}

const imageMap = {
  madflowersfootball: getTeamImageUrl('madflowers.png'),
  madflowersbasketball: getTeamImageUrl('madflowers.png'),
  madflowersesports: getTeamImageUrl('madflowers.png'),
  francestortillafootball: getTeamImageUrl('francestortilla.jpg'),
  loschavalesfootball: getTeamImageUrl('loschavales.jpg'),
  jogabonitofootball: getTeamImageUrl('jogabonito.jpg'),
  parlamentofootball: getTeamImageUrl('parlamentofc.PNG'),
  yayovallekanofootball: getTeamImageUrl('yayovallekano.png'),
  talleresriscalfootball: getTeamImageUrl('talleresriscal.png'),
  thenewfffootball: getTeamImageUrl('thenewff.png'),
  westsanferfootball: getTeamImageUrl('westsanferunited.png'),
  alianzefootball: getTeamImageUrl('eialianze.jpg'),
  cataratascityfootball: getTeamImageUrl('cataratascity.png'),
  intermitentefootball: getTeamImageUrl('intermitente.png'),
  entreviasbasketball: getTeamImageUrl('cbentrevias.png'),
  jvkbasketball: getTeamImageUrl('jvk.jpeg'),
  losabuelosbasketball: getTeamImageUrl('losabuelos.png'),
  crotofrotobasketball: getTeamImageUrl('crotofroto.png'),
  ciudadmuchachosbasketball: getTeamImageUrl('cdciudad.jpg'),
  ensanchebasketball: getTeamImageUrl('ensanchedevallecas.png'),
  basketthundersbasketball: getTeamImageUrl('vallekasbasketthunder.png'),
  skrotopiabasketball: getTeamImageUrl('skrotopia.png'),
  trifasicbluntersbasketball: getTeamImageUrl('trifasicblunters.png'),
  ursulinosbasketball: getTeamImageUrl('ursulinos.png'),
  antworkbasketball: getTeamImageUrl('antwork.png'),
  default: getTeamImageUrl('madflowers.png'),
};

export default imageMap;
