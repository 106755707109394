import React from 'react';
import { Box } from '@mui/material';
import useStyles from '../../../styles/styles';
import {useMediaQuery} from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TableChartIcon from '@mui/icons-material/TableChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EChartsPointsComponent from '../../charts/EChartsPointsComponent';
import EChartsAssistsComponent from '../../charts/EChartsAssistsComponent';
import EChartsReboundsComponent from '../../charts/EChartsReboundsComponent';

const PlayerDisplayStatsBasketball = ({ stats, evolutionStats, hideGraphs }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    return (
        <Box className={isMobile ? classes.teamDisplayStatsBasketballMobile : classes.teamDisplayStatsBasketball}>
            <div className={classes.teamDisplayStatsFootballSectionTitle}> <AssessmentIcon /> Estadísticas principales</div>
            <div className={isMobile ? classes.teamDisplayStatsFootballMainStatsGridMobile : classes.teamDisplayStatsFootballMainStatsGrid}>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Partidos jugados</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.numberOfMatches}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Victorias</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.wins}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Derrotas</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.losses}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Puntos</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.points}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Rebotes</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.rebounds}</div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballGridItemName}>Asistencias</div>
                    <div className={classes.teamDisplayStatsFootballGridItemValue}>{stats.assists}</div>
                </div>
            </div>
            <div className={classes.teamDisplayStatsFootballSectionTitle}><TableChartIcon /> Estadísticas avanzadas</div>
            <div className={isMobile ? classes.teamDisplayStatsFootballMoreStatsGridMobile : classes.teamDisplayStatsFootballMoreStatsGrid}>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>Promedio</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Puntos por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.pointsPerMatch}</span>
                            </li>
                            <li>
                                <span>Asistencias por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.assistsPerMatch}</span>
                            </li>
                            <li>
                                <span>Rebotes por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.reboundsPerMatch}</span>
                            </li>
                            <li>
                                <span>Tapones por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.blocksPerMatch}</span>
                            </li>
                            <li>
                                <span>Robos por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.stealsPerMatch}</span>
                            </li>
                            <li>
                                <span>Tiros de campo por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.fieldGoalsMadePerMatch}</span>
                            </li>
                            <li>
                                <span>Tiros de 3 por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.threePointMadePerMatch}</span>
                            </li>
                            <li>
                                <span>Tiros libres por partido</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.freeThrowsMadePerMatch}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>Totales</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Puntos</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.points}</span>
                            </li>
                            <li>
                                <span>Asistencias</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.assists}</span>
                            </li>
                            <li>
                                <span>Rebotes</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.rebounds}</span>
                            </li>
                            <li>
                                <span>Tapones</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.blocks}</span>
                            </li>
                            <li>
                                <span>Robos</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.steals}</span>
                            </li>
                            <li>
                                <span>Tiros de campo</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.fieldGoalsMade}</span>
                            </li>
                            <li>
                                <span>Tiros de 3</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.threePointMade}</span>
                            </li>
                            <li>
                                <span>Tiros libres</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{stats.freeThrowsMade}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>Rendimiento</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Porcentaje de tiro</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{(stats.fieldGoalPercentage * 100).toFixed(0) + "%"}</span>
                            </li>
                            <li>
                                <span>Porcentaje de tiro de 3</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{(stats.threePointPercentage * 100).toFixed(0) + "%"}</span>
                            </li>
                            <li>
                                <span>Porcentaje de tiro libre</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{(stats.freeThrowPercentage * 100).toFixed(0) + "%"}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {hideGraphs && 
            <div>
                <div className={classes.teamDisplayStatsFootballSectionTitle}><ShowChartIcon /> Gráficos</div>
                <div className={classes.teamDisplayStatsFootballGraphs}>
                    <div className={classes.teamDisplayStatsFootballGraphTitle}>PUNTOS</div>
                    {evolutionStats && <div style={{ width: '100%', height: '400px' }}>
                        <EChartsPointsComponent data={evolutionStats}/>
                    </div>}
                    <div className={classes.teamDisplayStatsFootballGraphTitle}>ASISTENCIAS</div>
                    {evolutionStats && <div style={{ width: '100%', height: '400px' }}>
                        <EChartsAssistsComponent data={evolutionStats}/>
                    </div>}
                    <div className={classes.teamDisplayStatsFootballGraphTitle}>REBOTES</div>
                    {evolutionStats && <div style={{ width: '100%', height: '400px' }}>
                        <EChartsReboundsComponent data={evolutionStats}/>
                    </div>}
                </div>
            </div>
            }
        </Box>
    );
};

export default PlayerDisplayStatsBasketball;