import React from "react";
import { FaBookmark, FaCalendarDay } from "react-icons/fa";
import { MdStadium } from "react-icons/md";
import imageMap from "../../maps/imageMap";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";

function VotingDisplayMatchDetails({ voting }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  // Función para formatear la fecha quitando los segundos
  const formatDateTime = (dateTimeString) => {
    const parts = dateTimeString.split(" ");
    const datePart = parts[0];
    const timePart = parts[1];
    const [day, month, year] = datePart.split("/");
    const formattedDate = `${day}/${month}/${year}`;
    return `${formattedDate} ${timePart.slice(0, -3)}`; // Elimina los últimos 3 caracteres (segundos)
  };
  
  return (
    <div className={isMobile ? classes.votingDisplayMatchDetailsMobile : classes.votingDisplayMatchDetails}>
      <h3>Detalles del partido</h3>
      <div className={isMobile ? classes.votingDisplayMatchDetailsVersusMobile : classes.votingDisplayMatchDetailsVersus}>
        <img
          src={
            imageMap[voting.match.localTeam.badgeLocator] || imageMap["default"]
          }
          alt=""
        />
        <p>{voting.match.resultLocal}</p>
        <p>
          <span>VS</span>
        </p>
        <p>{voting.match.resultAway}</p>
        <img
          src={
            imageMap[voting.match.awayTeam.badgeLocator] || imageMap["default"]
          }
          alt=""
        />
      </div>
      <div className={classes.votingDisplayMatchMoreDetails}>
        <p>
          <FaBookmark size={14} /> Jornada {voting.match.matchweek}
        </p>
        <p>
          <FaCalendarDay /> {formatDateTime(voting.match.date)}
        </p>
        <p>
          <MdStadium /> {voting.match.stadium}
        </p>
      </div>
    </div>
  );
}

export default VotingDisplayMatchDetails;
