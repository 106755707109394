import React from "react";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import { FaHandshake, FaHands } from "react-icons/fa";
import { BsPersonArmsUp } from "react-icons/bs";
import { GiStrong } from "react-icons/gi";
import { RiTeamFill } from "react-icons/ri";

function Phylosophy() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  return (
    <section id="philosophy" className={classes.aboutUsSection}>
      <h2 className={classes.aboutUsTitle}>Nuestra Filosofía</h2>

      <p>
        En Mad Flowers, creemos que el deporte
        es una herramienta fundamental para la educación y el desarrollo
        personal. Por ello, promovemos una formación integral de nuestros
        jugadores, basada en los siguientes valores:
      </p>

      <p className={classes.aboutUsValues}><FaHandshake size={16}/> Respeto</p>
      <p>
        Enseñamos a nuestros jugadores a respetar a sus compañeros, rivales,
        árbitros y público.
      </p>
      <p className={classes.aboutUsValues}><BsPersonArmsUp size={16}/> Responsabilidad</p>
      <p>
        Fomentamos la responsabilidad individual y colectiva, tanto dentro como
        fuera del campo.
      </p>
      <p className={classes.aboutUsValues}><GiStrong size={16}/>Esfuerzo</p>
      <p>
        Motivamos a nuestros jugadores a dar lo mejor de sí mismos en cada
        entrenamiento y partido.
      </p>
      <p className={classes.aboutUsValues}><RiTeamFill size={16}/> Trabajo en equipo</p>
      <p>
        Insistimos en la importancia de la colaboración y el compañerismo para
        alcanzar objetivos comunes.
      </p>

      <p className={classes.aboutUsAfterValues}>
        Además de estos valores, también creemos que el deporte debe ser
        divertido y una fuente de satisfacción personal. Por ello, nos
        esforzamos por crear un ambiente positivo y de aprendizaje en el que
        todos los jugadores se sientan acogidos y valorados.
      </p>

      <h3 className={classes.aboutUsSubtitle}>
        ¿Quieres formar parte de nuestro club?
      </h3>

      <p>
        Si compartes nuestra filosofía y quieres formar parte de un club con un
        ambiente familiar y una gran tradición deportiva, no dudes en
        contactarnos.
      </p>
    </section>
  );
}

export default Phylosophy;
