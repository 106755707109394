import React, { useEffect, useRef } from 'react';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';

const FullRatingsDisplayFootball = ({ ratings }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    return (
            <div>
                <div className={classes.playerDisplayDetailsRatingsRating}>
                    <div className={classes.playerDisplayDetailsRatingsRatingTitle}>
                        VALORACIÓN GENERAL
                    </div>
                    <div className={classes.playerDisplayDetailsRatingsRatingValue}>
                        {ratings.rating}
                    </div>
                </div>
                <div className={isMobile ? classes.teamDispteamDisplayStatsFootballMoreStatsGridMobile : classes.teamDisplayStatsFootballMoreStatsGrid}>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>RITMO {ratings.pace}</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Aceleración</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.acceleration}</span>
                            </li>
                            <li>
                                <span>Velocidad</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.speed}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>TIRO {ratings.shooting}</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Posicionamiento</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.positioning}</span>
                            </li>
                            <li>
                                <span>Finalización</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.finishing}</span>
                            </li>
                            <li>
                                <span>Potencia de tiro</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.shotPower}</span>
                            </li>
                            <li>
                                <span>Tiro lejano</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.longShooting}</span>
                            </li>
                            <li>
                                <span>Voleas</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.volleys}</span>
                            </li>
                            <li>
                                <span>Penaltis</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.penalties}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>PASE {ratings.passing}</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Visión</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.vision}</span>
                            </li>
                            <li>
                                <span>Centros</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.crossing}</span>
                            </li>
                            <li>
                                <span>Tiro libre</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.fkAccuracy}</span>
                            </li>
                            <li>
                                <span>Pases cortos</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.shortPassing}</span>
                            </li>
                            <li>
                                <span>Pases largos</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.longPassing}</span>
                            </li>
                            <li>
                                <span>Efecto</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.curve}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>REGATE {ratings.dribbling}</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Agilidad</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.agility}</span>
                            </li>
                            <li>
                                <span>Equilibrio</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.balance}</span>
                            </li>
                            <li>
                                <span>Reacciones</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.reactions}</span>
                            </li>
                            <li>
                                <span>Control de balón</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.ballControl}</span>
                            </li>
                            <li>
                                <span>Regates</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.dribbling}</span>
                            </li>
                            <li>
                                <span>Compostura</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.composure}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>DEFENSA {ratings.defending}</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Intercepciones</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.interceptions}</span>
                            </li>
                            <li>
                                <span>Precisión de cabeza</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.headingAccuracy}</span>
                            </li>
                            <li>
                                <span>Capacidad defensiva</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.defensiveAwareness}</span>
                            </li>
                            <li>
                                <span>Entrada normal</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.standingTackle}</span>
                            </li>
                            <li>
                                <span>Entrada agresiva</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.slidingTackle}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.teamDisplayStatsFootballGridItem}>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTitle}>FÍSICO {ratings.physicality}</div>
                    <div className={classes.teamDisplayStatsFootballMoreStatsTable}>
                        <ul>
                            <li>
                                <span>Salto</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.jumping}</span>
                            </li>
                            <li>
                                <span>Resistencia</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.stamina}</span>
                            </li>
                            <li>
                                <span>Fuerza</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.strength}</span>
                            </li>
                            <li>
                                <span>Agresividad</span>
                                <span className={classes.teamDisplayStatsFootballMoreStatsValue}>{ratings.aggression}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default FullRatingsDisplayFootball;