import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import Footer from "../../components/Footer";
import { FaRegEnvelope, FaRegEnvelopeOpen } from "react-icons/fa";
import imageMap from "../../maps/imageMap";
import VotingEntry from "../../components/votings/VotingEntry";
import CookiesNecessaryNonAccepted from "../../components/cookies/CookiesNecessaryNonAccepted";

function getWinnerOfVoting(idVoting) {}

const Votings = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const [currentVotings, setCurrentVotings] = useState([]);
  const [lastVotings, setLastVotings] = useState([]);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);

  useEffect(() => {
    setHasAcceptedCookies(sessionStorage.getItem("cookiesAccepted"));

    // Realizar una solicitud GET al backend de Spring
    axios
      .get("/voting/current")
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        setCurrentVotings(response.data);
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error(
          "Error al obtener los datos de las votaciones actuales:",
          error
        );
      });
    axios
      .get("/voting/last")
      .then((response) => {
        // Al recibir la respuesta exitosa, establecer los datos en el estado del componente
        setLastVotings(response.data);
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error(
          "Error al obtener los datos de las ultimas votaciones:",
          error
        );
      });
  }, []);
  if (hasAcceptedCookies) {
    return (
      <div>
        <div
          className={
            isMobile ? classes.votingsContainerMobile : classes.votingsContainer
          }
        >
          <h2>
            <FaRegEnvelopeOpen size={20} /> Votaciones actuales
          </h2>
          <p>Aquí se encuentran las votaciones que se encuentran activas ahora mismo.</p>
          <div
            className={
              isMobile
                ? classes.votingsCurrentContainerMobile
                : classes.votingsCurrentContainer
            }
          >
            {currentVotings.map((voting) => (
              <VotingEntry key={voting.idVoting} voting={voting} />
            ))}
          </div>
          <h2>
            <FaRegEnvelope size={20} /> Últimas votaciones
          </h2>
          <p>Aquí se encuentran las últimas 5 votaciones que han tenido lugar.</p>
          <div
            className={
              isMobile
                ? classes.votingsLastContainerMobile
                : classes.votingsLastContainer
            }
          >
            {lastVotings.map((voting) => (
              <VotingEntry key={voting.idVoting} voting={voting} />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return <CookiesNecessaryNonAccepted />;
  }
};

export default Votings;
