const getBackgroundImageUrl = ( playerImageName ) => {
  return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/backgrounds/' + playerImageName;
}

const backgroundImageMap = {
    section: getBackgroundImageUrl('sectionBackground.png'),
    football: getBackgroundImageUrl('background4.JPG'),
    basketball: 'https://blog.nasm.org/hubfs/Training%20Basketball%20Players-1.jpg',
    esports: "https://cimg.co/news/83054/209751/nft-esports-puntos-comun-divergencias-cryptonews.jpeg",
    footballList: 'https://i0.wp.com/fivethirtyeight.com/wp-content/uploads/2022/11/GettyImages-1442587075-e1668806020544.jpg?quality=90&strip=info&w=1024&ssl=1',
    basketballList: 'https://branded.disruptsports.com/cdn/shop/articles/how-are-basketballs-made_1862x.jpg?v=1603278517',
    esportsList: "https://cimg.co/news/83054/209751/nft-esports-puntos-comun-divergencias-cryptonews.jpeg",
    default: "https://4kwallpapers.com/images/wallpapers/dark-background-abstract-background-network-3d-background-2560x1080-8324.png",
    playerBackground: getBackgroundImageUrl('playerSectionBackground.png'),
  };
  
  export default backgroundImageMap;