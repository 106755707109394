import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import BasicRatingsFootballChart from '../ratings/BasicRatingsFootball';
import BasicRatingsGoalkeeperChart from '../ratings/BasicRatingsGoalkeeper';
import PlayerDisplayPersonal from '../players/PlayerDisplayPersonal';
import FullRatingsDisplayFootball from '../ratings/FullRatingsDisplayFootball';

const PlayerDisplayDetails = ({ player }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const [socials, setSocials] = useState([]);

    const [ratings, setRatings] = useState([]);

    useEffect(() => {
        // Obtener la lista de opciones mediante Axios
        axios.get('/players/' + player.idPlayer + '/socials')
          .then(response => {
            setSocials(response.data);
          })
          .catch(error => {
            console.error('Error fetching player socials:', error);
          });
      }, []);

    useEffect(() => {
    // Obtener la lista de opciones mediante Axios
    axios.get('/players/' + player.idPlayer + '/footballratings')
        .then(response => {
        setRatings(response.data);
        })
        .catch(error => {
        console.error('Error fetching player ratings:', error);
        });
    }, []);

    function getFootballPlayerRatings(data) {
        const ratings = [
            data.pace,
            data.shooting,
            data.passing,
            data.dribbling,
            data.defending,
            data.physicality,
          ];
          return ratings;
    }

    function getGoalkeeperPlayerRatings(data) {
        const ratings = [
            data.pace,
            data.diving,
            data.handling,
            data.positioning,
            data.reflexes,
            data.kicking,
          ];
          return ratings;
    }

    return (
        <Box className={isMobile ? classes.playerDisplayDetailsMobile : classes.playerDisplayDetails}>
            <div className={isMobile ? classes.playerDisplayDetailsPersonalMobile : classes.playerDisplayDetailsPersonal}>
                <div className={isMobile ? classes.playerDisplayDetailsPersonalTitleMobile : classes.playerDisplayDetailsPersonalTitle}>DETALLES PERSONALES</div>
                <PlayerDisplayPersonal player={player} socials={socials}/>
            </div>
            { player.team.sport === 'football' ? (
                <div className={isMobile ? classes.playerDisplayDetailsRatingsMobile : classes.playerDisplayDetailsRatings}>
                    <div className={isMobile ? classes.playerDisplayDetailsPersonalTitleMobile : classes.playerDisplayDetailsPersonalTitle}>VALORACIONES DEL JUGADOR</div>
                    <div className={isMobile ? classes.playerDisplayDetailsRatingsContainerMobile : classes.playerDisplayDetailsRatingsContainer}>
                        <div className={isMobile ? classes.playerDisplayDetailsRatingsGraphicMobile : classes.playerDisplayDetailsRatingsGraphic}>
                            {player.position === 'PORTERO' ? (
                            <BasicRatingsGoalkeeperChart ratings={getGoalkeeperPlayerRatings(ratings)} />
                            ) : (
                            <BasicRatingsFootballChart ratings={getFootballPlayerRatings(ratings)} />
                            )}
                        </div>
                        <div className={isMobile ? classes.playerDisplayDetailsRatingsElementMobile : classes.playerDisplayDetailsRatingsElement}>
                            <FullRatingsDisplayFootball ratings={ratings}/>
                        </div>
                    </div>
                </div>
                ) : (
                    <div></div>
                )}
        </Box>
    );
};

export default PlayerDisplayDetails;