import React, { useEffect, useState } from 'react';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import { Box } from '@mui/material';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import PlayerDisplayHistory from './PlayerDisplayHistory';
import PlayerDisplayStats from './PlayerDisplayStats';
import PlayerDisplayDetails from './PlayerDisplayDetails';
import PlayerDisplayAwards from './PlayerDisplayAwards';

const PlayerDisplayTabs = ({ player }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const [matches, setMatches] = useState([]);

    const [activeTab, setActiveTab] = useState('details'); // Estado para el seguimiento de la pestaña activa

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
          axios.get('/players/' + player.idPlayer + '/matches')
          .then(response => {
            setMatches(response.data);
          })
          .catch(error => {
            console.error('Error fetching matches:', error);
          });
    }, []);

    return (
        <Box>
            <div className={classes.teamDisplayTabs}>
                <button
                    className={activeTab === 'details' ? classes.teamDisplayActiveTab : classes.teamDisplayTab}
                    onClick={() => handleTabChange('details')}
                >
                Detalles
                </button>
                <button
                    className={activeTab === 'stats' ? classes.teamDisplayActiveTab : classes.teamDisplayTab}
                    onClick={() => handleTabChange('stats')}
                >
                Estadísticas
                </button>
                <button
                    className={activeTab === 'history' ? classes.teamDisplayActiveTab : classes.teamDisplayTab}
                    onClick={() => handleTabChange('history')}
                >
                Historial
                </button>
                <button
                    className={activeTab === 'awards' ? classes.teamDisplayActiveTab : classes.teamDisplayTab}
                    onClick={() => handleTabChange('awards')}
                >
                Premios
                </button>
            </div>
            <div className={classes.teamDisplayMainSection}>
                {activeTab === 'details' && <PlayerDisplayDetails player={player} />}
                {activeTab === 'stats' && <PlayerDisplayStats player={player} />}
                {activeTab === 'history' && <PlayerDisplayHistory matches={matches} />}
                {activeTab === 'awards' && <PlayerDisplayAwards player={player} />}
            </div>
        </Box>
    );
};

export default PlayerDisplayTabs;