const getKitImageUrl = ( kitName ) => {
    return 'https://madflowersbucket.s3.eu-west-3.amazonaws.com/kits/' + kitName;
  }
  
  const kitsMap = {
    footballhome: getKitImageUrl('footballhome.png'),
    footballaway: getKitImageUrl('footballaway.png'),
    footballgoalkeeperhome: getKitImageUrl('footballgoalkeeperhome.png'),
    basketballhome: getKitImageUrl('basketballhome.png'),
    default: getKitImageUrl('footballhome.png'),
  };
  
  export default kitsMap;