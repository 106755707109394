import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig'; // Importa el objeto Axios configurado
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import imageMap from '../../maps/imageMap.js';
import useStyles from '../../styles/styles';
import Countdown from './Countdown.jsx';
import { MdStadium, MdCalendarMonth  } from "react-icons/md";

const Match = ({ icon, idTeam }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [partido, setPartido] = useState(null); // Inicializa con null para indicar que los datos aún no están disponibles
  const [loading, setLoading] = useState(true); // Nuevo estado para indicar si la solicitud está en curso
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`/teams/${idTeam}/matches/next`);
            setPartido(response.data);
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        } finally {
            setLoading(false); // Indica que la solicitud ha terminado, ya sea con éxito o con error
        }
    };

    fetchData();
  }, [idTeam]); // Dependencia para que useEffect se ejecute cuando idTeam cambia

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (partido && Object.keys(partido).length > 0) {
    return (
      <div className={isMobile ? classes.nextMatchRootMobile : classes.nextMatchRoot}>
        <h2 className={isMobile ? classes.nextMatchTitleMobile : classes.nextMatchTitle}>
          {icon} Próximo Partido
        </h2>
        {partido ? (
          <div className={isMobile ? classes.teamLogosMobile : classes.teamLogos}>
            <img src={imageMap[partido.localTeam.badgeLocator]} alt={partido.localTeam.name} />
            <span className={isMobile ? classes.vsTextMobile : classes.vsText}>VS</span>
            <img src={imageMap[partido.awayTeam.badgeLocator]} alt={partido.awayTeam.name} />
          </div>
        ) : null}
        {partido ? <p><MdStadium /> {partido.stadium}</p> : null}
        {partido ? <p><MdCalendarMonth /> {partido.date}</p> : null}
        <Countdown date={partido.date}></Countdown>
      </div>
    );
  } else {
    <div className={isMobile ? classes.nextMatchRootMobile : classes.nextMatchRoot}>
    </div>
  }
  
};

export default Match;
