import React from 'react';
import useStyles from '../../styles/styles';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import sponsorMap from '../../maps/sponsorMap';
import { Link } from 'react-router-dom';

const Sponsors = () => {
    const classes = useStyles();

    const sponsorsList = [
        { id: 1, name: 'Bar Madrid', image: sponsorMap["barmadrid"] },
    ];

    return (
        <section className={classes.about}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <AccountBalanceWalletIcon /> Patrocinadores
                </h2>
                <div className={classes.description}>
                    <p>Nuestro proyecto se ayuda gracias a nuestros patrocinadores.</p>
                </div>
                <div className={classes.sponsors}>
                    {/* Mapea la lista de sponsors para renderizar cada uno */}
                    {/* {sponsorsList.map((sponsor) => (
                        <a key={sponsor.id} className={classes.sponsorItem} href={"https://barmadrid.mitd.es/"} target="_blank" rel="noreferrer">
                            <img src={sponsor.image} alt={sponsor.name} className={classes.sponsorItemImage}/>
                            <p className={classes.sponsorItemName}>{sponsor.name}</p>
                        </a>
                    ))} */}
                </div>
            </div>
        </section>
    );
};

export default Sponsors;
