import React, { useState } from 'react';
import axios from '../../axiosConfig';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import { FaPhoneAlt  } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const ContactForm = () => {

  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);
      const response = await axios.post('/contact/', {
        name,
        email,
        message,
      });
      setIsSubmitting(false);
      setSuccess('¡Gracias por tu mensaje!');
    } catch (error) {
      setError(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={isMobile ? classes.contactFormContainerMobile : classes.contactFormContainer}>
      <form onSubmit={handleSubmit}>
        <h2 className={classes.contactFormTitle}><IoMdMail size={16}/> Contacto</h2>
        <p>¿Tienes alguna duda o sugerencia? No dudes en ponerte en contacto con nosotros a través de este formulario.</p>
        {error && <div className={classes.contactFormError}>{error}</div>}
        <div className={classes.contactFormGroup}>
          <h4>DATOS DE CONTACTO</h4>
          <label htmlFor="name" className={classes.contactFormLabel}>Nombre</label>
          {errorName && <div className={classes.contactFormError}>{errorName}</div>}
          <input
            type="text"
            className={classes.contactFormInput}
            id="name"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              if (event.target.value.length < 10) {
                setErrorName('El mensaje debe tener al menos 10 carácteres.');
              } else {
                setErrorName('');
              }
            }}
            required
          />
          <label htmlFor="email" className={classes.contactFormLabel}>Correo electrónico</label>
          {errorEmail && <div className={classes.contactFormError}>{errorEmail}</div>}
          <input
            type="email"
            className={classes.contactFormInput}
            id="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              if (event.target.value.length < 10) {
                setErrorEmail('El mensaje debe tener al menos 10 carácteres.');
              } else {
                setErrorEmail('');
              }
            }}
            required
          />
        </div>
        <div className={classes.contactFormGroup}>
          <label htmlFor="message" className={classes.contactFormLabel}>Mensaje</label>
          {errorMessage && <div className={classes.contactFormError}>{errorMessage}</div>}
          <textarea
            className={classes.contactFormTextArea}
            id="message"
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
              if (event.target.value.length < 10) {
                setErrorMessage('El mensaje debe tener al menos 10 carácteres.');
              } else {
                setErrorMessage('');
              }
            }}
            required
          />
        </div>
        {success && <div className={classes.contactFormSuccess}>{success}</div>}
        <button type="submit" className={classes.contactFormButton} disabled={isSubmitting}>
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </div>
    
  );
};

export default ContactForm;