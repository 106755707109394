import React, { useState, useEffect } from 'react';
import { MdAccessTime } from "react-icons/md";
import useStyles from '../../styles/styles';
import { useMediaQuery } from '@mui/material';

const Countdown = ({ date }) => {
  const [fechaObjetivo, setFechaObjetivo] = useState(new Date(parseDate(date)));
  const [tiempoRestante, setTiempoRestante] = useState(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const classes = useStyles();

  function parseDate(fecha) {
    const parts = fecha.split(" ");
    const dateParts = parts[0].split("/");
    const timeParts = parts[1].split(":");

    return new Date(
      dateParts[2],
      dateParts[1] - 1,
      dateParts[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    );
  }

  useEffect(() => {
    const intervalo = setInterval(() => {
      const ahora = new Date();
      const diferencia = fechaObjetivo - ahora;

      if (diferencia <= 0) {
        clearInterval(intervalo);
        setTiempoRestante("¡El partido ya ha comenzado!");
      } else {
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
        const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

        if (dias > 0) {
            setTiempoRestante(`${dias} d ${horas} h ${minutos} min`);
        } else {
            if (horas > 0) {
                setTiempoRestante(`${horas} h ${minutos} min`);
            } else {
                setTiempoRestante(`${minutos} min`);
            }
        }
      }
    }, 1000);

    return () => clearInterval(intervalo);
  }, [fechaObjetivo]);

  return (
      <p><MdAccessTime size={18}/><span className={isMobile ? classes.countdownMobile : classes.countdown}>{tiempoRestante}</span></p>
  );
};

export default Countdown;