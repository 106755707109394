import React, { useEffect, useState } from "react";
import axios from "../../axiosConfig"; // Importa el objeto Axios configurado
import useStyles from "../../styles/styles";
import { useMediaQuery } from "@mui/material";
import staffMap from "../../maps/staffMap";

function Team() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    // Realizar una solicitud GET al backend de Spring
    axios
      .get("/staff/")
      .then((response) => {
        // Al recibir la respuesta exitosa, dividir los datos en dos grupos: Presidentes/CEOs y resto del equipo
        const presidents = response.data.filter(
          (staff) => staff.role === "Presidente" || staff.role === "CEO"
        );
        const otherStaff = response.data.filter(
          (staff) =>
            staff.role !== "Presidente" &&
            staff.role !== "CEO" &&
            staff.team === null
        );
        setData({ presidents, otherStaff });
      })
      .catch((error) => {
        // Manejo de errores en caso de que la solicitud falle
        console.error("Error al obtener los datos:", error);
      });
  }, []);

  return (
    <section id="team" className={classes.aboutUsSection}>
      <h2 className={classes.aboutUsTitle}>Nuestro Equipo</h2>
      <p>En esta sección, te invitamos a conocer a los integrantes del equipo que trabajan con pasión y dedicación para lograr nuestros objetivos.</p>
      <h3 className={classes.aboutUsSubtitle}>Junta directiva</h3>
      <div
        className={
          isMobile
            ? classes.aboutUsTeamStaffContainerMobile
            : classes.aboutUsTeamStaffContainer
        }
      >
        {data.presidents &&
          data.presidents.map((staff) => (
            <div key={staff.id} className={classes.aboutUsTeamStaffMember}>
              <div className={classes.aboutUsTeamStaffMemberImage}>
                <img
                  src={staffMap[staff.imageLocator] || staffMap["default"]}
                  alt={staff.person.name + " " + staff.person.surname}
                />
              </div>
              <div className={classes.aboutUsTeamStaffMemberDetails}>
                <div className={classes.aboutUsTeamStaffMemberDetailsName}>
                  {staff.person.name + " " + staff.person.surname}
                </div>
                <div className={classes.aboutUsTeamStaffMemberDetailsRole}>
                  {staff.role}
                </div>
              </div>
            </div>
          ))}
      </div>
      <h3 className={classes.aboutUsSubtitle}>Resto del equipo</h3>
      <div
        className={
          isMobile
            ? classes.aboutUsTeamStaffContainerMobile
            : classes.aboutUsTeamStaffContainer
        }
      >
        {data.otherStaff &&
          data.otherStaff.map((staff) => (
            <div key={staff.id} className={classes.aboutUsTeamStaffMember}>
              <div className={classes.aboutUsTeamStaffMemberImage}>
                <img
                  src={staffMap[staff.imageLocator] || staffMap["default"]}
                  alt={staff.person.name + " " + staff.person.surname}
                />
              </div>
              <div className={classes.aboutUsTeamStaffMemberDetails}>
                <div className={classes.aboutUsTeamStaffMemberDetailsName}>
                  {staff.person.name + " " + staff.person.surname}
                </div>
                <div className={classes.aboutUsTeamStaffMemberDetailsRole}>
                  {staff.role}
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default Team;
