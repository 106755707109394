import React from 'react';
import useStyles from '../../styles/styles';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link } from 'react-router-dom';

function handleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

const AboutUs = () => {

    const classes = useStyles();
    return (
        <section className={classes.about}>
            <div className={classes.container}>
                <h2 className={classes.title}>
                    <PeopleAltIcon /> Sobre nosotros
                </h2>
                <div className={classes.description}>
                    <p>
                        Mad Flowers es más que un equipo de fútbol y baloncesto. Nacido en Vallecas en 2022, somos una familia de amigos unidos por la pasión por el deporte y el compañerismo. En la cancha defendemos nuestros colores con garra y celebramos cada victoria juntos. Pero lo que realmente importa es la amistad, el apoyo mutuo y los momentos inolvidables que compartimos.
                    </p>
                    <p>
                    En Mad Flowers no solo se juega, se vive: risas, emociones y la alegría de compartir una pasión común con amigos de toda la vida. Somos un refugio donde disfrutar del deporte, fortalecer nuestra amistad y vivir experiencias inolvidables que van más allá de la competición. Si buscas un equipo donde crecer como jugador y como persona, donde la amistad sea tan importante como la victoria, Mad Flowers te abre sus puertas.
                    </p>
                    <p>
                        Ven todos los domingos a vivir la esencia de Mad Flowers: pasión, amistad y deporte en Vallecas.
                    </p>
                    <div className={classes.aboutUsButtonContainer}>
                        <Link to={"/about"} className={classes.aboutUsButton} onClick={handleClick}>Saber más</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;