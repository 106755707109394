import React from 'react';
import useStyles from '../../styles/styles';
import {useMediaQuery} from '@mui/material';
import playerBodyImageMap from '../../maps/playerBodyImageMap.js';
import iconMap from '../../maps/iconMap.js';
import backgroundImageMap from '../../maps/backgroundImageMap.js';
import { Box } from '@mui/material';

const PlayerDisplayHeader = ({ player }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const playerImage = playerBodyImageMap[player.imageLocator] || playerBodyImageMap["default"];

    const getBackgroundURL = (value) => {
      return backgroundImageMap[value] || backgroundImageMap["playerBackground"];
    };

    const headerBackground = getBackgroundURL("playerBackground");

    const backgroundHeaderStyle = {
        /* Estilos para el playerDisplayHeader si los tienes */
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: 'cover',
    };

    return (
      <div style={backgroundHeaderStyle}>
        
        <div className={classes.playerDisplayHeaderFilter}>
          <Box className={isMobile ? classes.playerDisplayHeaderMobile : classes.playerDisplayHeader}
               sx={isMobile ? { flexDirection: 'column' } : {}}>
            <div className={isMobile ? classes.playerDisplayHeaderContainerMobile : classes.playerDisplayHeaderContainer}>
              <div className={isMobile ? classes.playerDisplayImageMobile : classes.playerDisplayImage}>
                  <img
                      src={playerImage}
                      alt="Foto de jugador"
                      className={classes.playerDisplayBadgeImage}
                  />
              </div>
              <div className={isMobile ? classes.playerDisplayInfoMobile : classes.playerDisplayInfo}>
                
                <div className={isMobile ? classes.playerDisplayDetailsMobile : classes.playerDisplayDetails}>
                  <div className={classes.playerDisplayDetailsFilter}>
                    <p className={isMobile ? classes.playerDisplayPositionMobile : classes.playerDisplayPosition}>{player.position}</p>
                    <p className={isMobile ? classes.playerDisplayNameMobile : classes.playerDisplayName}><b>{player.number} / </b> {player.person.name} <b>{player.person.surname}</b></p>
                  </div>
                </div>
              </div>
            </div>
          </Box>
          
        </div>
      </div>
    );
};

// Función para calcular la edad a partir de la fecha de nacimiento
const calculateAge = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  if (today.getMonth() < birthDateObj.getMonth() || (today.getMonth() === birthDateObj.getMonth() && today.getDate() < birthDateObj.getDate())) {
      age--;
  }
  return age;
};

export default PlayerDisplayHeader;