import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import NotFoundPage from "./scenes/global/NotFoundPage";
import Dashboard from "./scenes/dashboard";
import Teams from "./scenes/team";
import TeamDisplay from "./scenes/teamDisplay";
import PlayerDisplay from "./scenes/playerDisplay";
import VotingDisplay from "./scenes/votingDisplay";
import Home from "./scenes/home";
import AboutUs from "./scenes/about";
import Contact from "./scenes/contact";
import Loading from "./scenes/loading";
import Votings from "./scenes/vote";
import CookiePolicy from "./scenes/cookies";
import { CookieConsent } from "./components/cookies/CookieConsent";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Analytics } from "@vercel/analytics/react"

function getSpecificDateAt20h(year, month, day) {
  const date = new Date(year, month - 1, day, 21, 0, 0, 0);
  return date;
}

function isEqualOrLater(date) {
  // Convertir la fecha introducida a un objeto Date
  const fechaComparacion = new Date(date);

  // Obtener la fecha actual
  const fechaActual = new Date();

  // Comparar las fechas
  return fechaComparacion.getTime() <= fechaActual.getTime();
}

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    const newDrawerOpenState = !drawerOpen;
    setDrawerOpen(newDrawerOpenState);
    
    if (!newDrawerOpenState) {
      document.getElementsByTagName('html')[0].style.overflow = "scroll";
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      document.getElementsByTagName('html')[0].style.overflow = "hidden";
    }
  };

  const [currentDate, setCurrentDate] = useState(new Date());
  const targetDate = getSpecificDateAt20h(2024, 4, 8); // Replace with your function
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
            <CookieConsent/>
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} onDrawerToggle={handleDrawerToggle} />
              <Sidebar open={drawerOpen} onClose={handleDrawerToggle} />
              <Routes>
                <Route path="/loading" element={<Loading targetDate={new Date()} />} />
                <Route path="/" element={<Home />} />
                <Route path="/team" element={<Teams />} />
                <Route path="/team/:teamId" element={<TeamDisplay />} />
                <Route path="/player/:playerId" element={<PlayerDisplay />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/vote" element={<Votings />} />
                <Route path="/vote/:votingId" element={<VotingDisplay />} />
                <Route path="/cookies" element={<CookiePolicy />} />
                {/* ... other routes */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </main>
            <Analytics />
        </div>
        <Analytics />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;